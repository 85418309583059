import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import { usePusher } from '../../contexts/PusherContext';
import { parseISO, differenceInMinutes, differenceInHours } from 'date-fns';
import ApprovalModal from '../tasks/ApprovalModal';
import EscalationModal from '../tasks/EscalationModal';
import ConversationModal from '../misc/ConversationModal';
import FakePic from '../feed/items/FakePic';
import './engagements.css';
import { ReactComponent as ConversationEmptyIcon } from '../../icons/conversation-empty.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error-exclamation.svg';

function EngagementList() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();
  const [selectedTask, setSelectedTask] = useState(null);
  const [isApproval, setIsApproval] = useState(false);
  const [taskLadder, setTaskLadder] = useState(null);
  const [successfulEngagements, setSuccessfulEngagements] = useState([]);
  const [selectedEngagement, setSelectedEngagement] = useState(null);

  const fetchTasks = async () => {
    try {
      setError(false);
      const token = await getAccessTokenSilently();
      const [approvals, escalations, successful] = await Promise.all([
        api.get('/approvals', {
          params: { campaign_id: null },
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get('/escalations', {
          params: { campaign_id: null },
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get('/dashboard/successful-engagements', {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const processedApprovals = approvals.data.map((item) => ({
        ...item,
        type: 'approval',
        date: new Date(item.scheduled_time),
      }));

      const processedEscalations = escalations.data.map((item) => ({
        ...item,
        type: 'escalation',
        date: new Date(item.created_at),
      }));

      // Get non-offline successful engagements
      const currentPeriod = successful.data.periods.find(
        (p) => p.period === 'all',
      );
      const nonOfflineEngagements =
        currentPeriod?.engagements?.filter(
          (engagement) => !engagement.offline,
        ) || [];
      setSuccessfulEngagements(nonOfflineEngagements);

      // Split approvals into reply and first message
      const replyApprovals = processedApprovals.filter(
        (item) => item.is_response,
      );
      const firstMessageApprovals = processedApprovals.filter(
        (item) => !item.is_response,
      );

      setTasks({
        replyApprovals,
        firstMessageCount: firstMessageApprovals.length,
        escalations: processedEscalations,
        allApprovals: [...replyApprovals, ...firstMessageApprovals],
      });
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const formatTimeAgo = (dateStr) => {
    const date = parseISO(dateStr);
    const now = new Date();
    const minutesAgo = differenceInMinutes(now, date);
    const hoursAgo = differenceInHours(now, date);

    if (minutesAgo < 60) {
      return {
        text: `${minutesAgo}m`,
        className: 'green',
      };
    } else if (hoursAgo < 48) {
      return {
        text: `${hoursAgo}h`,
        className: 'amber',
      };
    } else {
      return {
        text: 'Overdue',
        className: 'red',
      };
    }
  };

  const openModal = (task, next, tasks) => {
    setSelectedTask(task);
    setIsApproval(task.type === 'approval');
    setTaskLadder(tasks.slice(next));
  };

  const closeModal = () => {
    setSelectedTask(null);
    fetchTasks();
  };

  const chooseNext = () => {
    const hasNextTask = taskLadder.length > 1;
    if (hasNextTask) {
      setTaskLadder((prev) => {
        setSelectedTask(taskLadder[1]);
        return prev.slice(1);
      });
      return true;
    } else {
      closeModal();
      return false;
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!pusherChannel) return;

    pusherChannel.bind('oagent_status_update', () => {
      fetchTasks();
    });

    return () => {
      if (pusherChannel) {
        pusherChannel.unbind('oagent_status_update');
      }
    };
  }, [pusherChannel]);

  if (loading) {
    return (
      <div className='engagement-list'>
        <h1>Engagement</h1>
        <div className='dash-loading-state'></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='engagement-list'>
        <h1>Engagement</h1>
        <div className='dashboard-empty'>
          <ErrorIcon className='dashboard-error-icon' />
          <div>
            <h2 className='dashboard-empty-title'>
              We're having trouble getting your data
            </h2>
            <p className='dashboard-empty-text'>Try reloading the page</p>
          </div>
        </div>
      </div>
    );
  }

  if (
    tasks.replyApprovals.length === 0 &&
    tasks.escalations.length === 0 &&
    successfulEngagements.length === 0
  ) {
    return (
      <div className='engagement-list'>
        <h1>Engagement</h1>
        <div className='dashboard-empty'>
          <ConversationEmptyIcon className='dashboard-empty-icon' />
          <div>
            <h2 className='dashboard-empty-title'>
              Keep track of your conversations
            </h2>
            <p className='dashboard-empty-text'>
              Start your campaigns and we'll show any communications from your
              prospects here. View replies, escalations, and successful
              conversations.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='engagement-list'>
      <h1>Engagement</h1>
      <div className='engagement-area-wrapper'>
        {tasks.escalations.length > 0 && (
          <div className='section'>
            <h2>
              Your agent needs help <span>{tasks.escalations.length}</span>
            </h2>

            <div className='action-items-wrapper'>
              {tasks.escalations.map((escalation) => (
                <div
                  key={escalation.id}
                  className='action-item'
                  onClick={() => openModal(escalation, 0, tasks.escalations)}
                >
                  <img
                    src={escalation.known_org_logo_url || escalation.logo_url}
                    alt={escalation.company}
                  />
                  <div>
                    <h3>{escalation.company}</h3>
                    <p>Your agent needs help</p>
                  </div>
                  <span
                    className={formatTimeAgo(escalation.created_at).className}
                  >
                    {formatTimeAgo(escalation.created_at).text}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}

        {tasks.replyApprovals.length > 0 && (
          <div className='section'>
            <h2>
              New reply approvals <span>{tasks.replyApprovals.length}</span>
            </h2>

            <div className='action-items-wrapper'>
              {tasks.replyApprovals.map((approval) => (
                <div
                  key={approval.id}
                  className='action-item'
                  onClick={() => openModal(approval, 0, tasks.replyApprovals)}
                >
                  <img
                    src={
                      approval.known_org_logo_url ||
                      approval.prospects[0].logo_url
                    }
                    alt={approval.company}
                  />
                  <div>
                    <h3>{approval.company}</h3>
                    <p>Reply approval required</p>
                  </div>
                  <span
                    className={formatTimeAgo(approval.scheduled_time).className}
                  >
                    {formatTimeAgo(approval.scheduled_time).text}
                  </span>
                </div>
              ))}

              {tasks.firstMessageCount > 0 && (
                <p
                  className='more-approvals'
                  onClick={() => {
                    const firstMessageApproval = tasks.allApprovals.find(
                      (a) => !a.is_response,
                    );
                    if (firstMessageApproval) {
                      openModal(firstMessageApproval, 0, [
                        firstMessageApproval,
                      ]);
                    }
                  }}
                >
                  + {tasks.firstMessageCount} first message approvals
                </p>
              )}
            </div>
          </div>
        )}

        {successfulEngagements.length > 0 && (
          <div className='section'>
            <h2>
              New leads for you to handle{' '}
              <span>{successfulEngagements.length}</span>
            </h2>

            <div className='action-items-wrapper'>
              {successfulEngagements.map((engagement) => (
                <div
                  key={engagement.id}
                  className='action-item'
                  onClick={() => setSelectedEngagement(engagement)}
                >
                  <FakePic
                    url={engagement.logo_url}
                    name={engagement.name}
                    size={32}
                    type='org'
                  />
                  <div>
                    <h3>{engagement.name}</h3>
                    <p>Ready to take offline</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {selectedTask && isApproval && (
        <ApprovalModal
          show
          task={selectedTask}
          onHide={closeModal}
          chooseNext={chooseNext}
        />
      )}
      {selectedTask && !isApproval && (
        <EscalationModal show task={selectedTask} onHide={closeModal} />
      )}
      {selectedEngagement && (
        <ConversationModal
          show={true}
          onHide={() => setSelectedEngagement(null)}
          id={selectedEngagement.conversation_id}
        />
      )}
    </div>
  );
}

export default EngagementList;
