import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import { formatDistanceToNow } from 'date-fns';
import WarmthFactors from './WarmthFactors';
import './dashboard-opportunities.css';
import { Button } from 'react-bootstrap';
import { ReactComponent as FlameIcon } from '../../icons/flame.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error-exclamation.svg';
import { ReactComponent as WarningComputerIcon } from '../../icons/warning-computer.svg';
import { ReactComponent as WarningRightArrowIcon } from '../../icons/warning-right-arrow.svg';
import { ReactComponent as WarningLinkIcon } from '../../icons/warning-link.svg';
import { ReactComponent as WhatsappWarningIcon } from '../../icons/whatsapp-warning.svg';
import ActivityConfirmModal from './ActivityConfirmModal';
import EngagementActivities from './EngagementActivities';

function DashboardOpportunities({
  clickTrackingDisabled,
  websiteVisitDisabled,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [copiedStates, setCopiedStates] = useState({});
  const [opportunities, setOpportunities] = useState({
    hot: [],
    warm: [],
    contacted: [],
  });
  const [whatsappStatus, setWhatsappStatus] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const [oppsResponse, whatsappResponse] = await Promise.all([
          api.get('/prospects', {
            headers: { Authorization: `Bearer ${token}` },
          }),
          api.get('/whatsapp/status', {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        // Group prospects by organization
        const orgGroups = {};
        oppsResponse.data.forEach((prospect) => {
          const orgKey = prospect.known_org?.id || `individual-${prospect.id}`;
          if (!orgGroups[orgKey]) {
            orgGroups[orgKey] = {
              org: prospect.known_org,
              prospects: [],
              maxWarmth: 0,
              hasReplied: false,
              hasActivityDone: false,
              activityDoneTime: null,
            };
          }
          orgGroups[orgKey].prospects.push(prospect);
          orgGroups[orgKey].maxWarmth = Math.max(
            orgGroups[orgKey].maxWarmth,
            prospect.warmth || 0,
          );
          // If any prospect has replied, mark the org group
          if (prospect.has_replied) {
            console.log(
              `Prospect ${prospect.name} ${prospect.known_org?.name} has replied to email`,
            );
            orgGroups[orgKey].hasReplied = true;
          }
          if (prospect.activity_done) {
            orgGroups[orgKey].hasActivityDone = true;
            orgGroups[orgKey].activityDoneTime = prospect.activity_done;
          }
        });

        // Convert to opportunities, separating active from contacted
        const hot = [];
        const warm = [];
        const contacted = [];

        Object.values(orgGroups).forEach((group) => {
          const opportunity = {
            ...group.prospects[0],
            org: group.org,
            warmth: group.maxWarmth,
            prospects: group.prospects,
            activityDoneTime: group.activityDoneTime,
          };

          if (group.hasReplied) {
            return false;
          }
          if (group.hasActivityDone) {
            contacted.push(opportunity);
          } else if (group.maxWarmth >= 40) {
            hot.push(opportunity);
          } else if (group.maxWarmth > 15) {
            warm.push(opportunity);
          }
        });

        // Sort all arrays by warmth
        hot.sort((a, b) => b.warmth - a.warmth);
        warm.sort((a, b) => b.warmth - a.warmth);
        contacted.sort(
          (a, b) => new Date(b.activityDoneTime) - new Date(a.activityDoneTime),
        );

        setOpportunities({ hot, warm, contacted });
        setWhatsappStatus(whatsappResponse.data.status);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
        setLoading(false);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  const OpportunityCard = ({ opportunity, completedView }) => {
    const { org, prospects } = opportunity;
    const linkedInProspects = prospects.filter((p) => p.linkedin_url);

    const [showActivityModal, setShowActivityModal] = useState(false);
    const [showActivitiesModal, setShowActivitiesModal] = useState(false);
    const [activityType, setActivityType] = useState(null);

    const handleActivityDone = (activityDone) => {
      if (activityDone) {
        // Optimistically update opportunities
        setOpportunities((prev) => {
          const now = new Date().toISOString();
          const updatedOpportunity = {
            ...opportunity,
            activityDoneTime: now,
          };

          // Remove from hot/warm arrays
          const newHot = prev.hot.filter((opp) => opp !== opportunity);
          const newWarm = prev.warm.filter((opp) => opp !== opportunity);

          // Add to contacted array
          const newContacted = [updatedOpportunity, ...prev.contacted];

          return {
            hot: newHot,
            warm: newWarm,
            contacted: newContacted,
          };
        });
      }
      setShowActivityModal(false);
    };

    const handleConnectionRequest = () => {
      setActivityType('connection');
      setShowActivityModal(true);
    };

    const openLinkedInProfile = () => {
      // If there's only one prospect, open their profile directly
      if (linkedInProspects.length === 1) {
        window.open(linkedInProspects[0].linkedin_url, '_blank');
        handleConnectionRequest();
        return;
      }

      // If there are multiple prospects, open all profiles in new tabs
      linkedInProspects.forEach((prospect) => {
        window.open(prospect.linkedin_url, '_blank');
      });

      handleConnectionRequest();
    };

    const handleCall = () => {
      setActivityType('call');
      setShowActivityModal(true);
    };

    const handleCopyNumber = () => {
      navigator.clipboard.writeText(org.phone_number);
      setCopiedStates((prev) => ({
        ...prev,
        [org.id]: true,
      }));
      setTimeout(() => {
        setCopiedStates((prev) => ({
          ...prev,
          [org.id]: false,
        }));
      }, 2000);
      setActivityType('call');
      setShowActivityModal(true);
    };

    return (
      <div className='opportunity-card'>
        <div className='top-wrapper'>
          <div className='org-info'>
            {org?.logo_url ? (
              <img src={org.logo_url} alt={org.name} className='org-logo' />
            ) : (
              <div className='org-logo-placeholder'>
                {org?.name?.charAt(0) || '?'}
              </div>
            )}
            <div className='org-name'>
              {completedView && org?.name}
              {!completedView && (org?.name || 'Individual Prospect')}{' '}
              {completedView && (
                <div className='completed-time'>
                  {formatDistanceToNow(new Date(completedView))} ago
                </div>
              )}
            </div>
          </div>
          <div className='opportunity-actions'>
            <Button
              className='activity-history-button'
              onClick={() => setShowActivitiesModal(true)}
            >
              Activity History
            </Button>
          </div>
        </div>
        <div className='opportunity-details'>
          <WarmthFactors prospects={prospects} completedTime={completedView} />
        </div>

        <EngagementActivities
          show={showActivitiesModal}
          onHide={() => setShowActivitiesModal(false)}
          orgId={opportunity.org.id}
          orgName={opportunity.org.name}
        />

        {!completedView && (
          <>
            {linkedInProspects.length > 0 && (
              <Button
                className='connection-btn btn-link'
                onClick={openLinkedInProfile}
              >
                Send connection request(s)
              </Button>
            )}
            {org?.phone_number && (
              <div className='phone-number'>
                <a
                  className='connection-btn btn-link'
                  href={`tel:${org.phone_number}`}
                  onClick={handleCall}
                >
                  Call {org?.name}
                </a>
                <span> | </span>
                <Button
                  className='connection-btn btn-link'
                  onClick={handleCopyNumber}
                >
                  {copiedStates[org.id] ? 'Copied!' : 'Copy phone number'}
                </Button>
              </div>
            )}
            <ActivityConfirmModal
              show={showActivityModal}
              onHide={handleActivityDone}
              activityType={activityType}
              prospects={prospects}
              title={opportunity.org.name}
            />
          </>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div className='dashboard-opportunities'>
        <h1>Opportunity</h1>
        <div className='dash-loading-state'></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='dashboard-opportunities'>
        <h1>Opportunity</h1>
        <div className='dashboard-empty'>
          <ErrorIcon className='dashboard-error-icon' />
          <div>
            <h2 className='dashboard-empty-title'>
              We're having trouble getting your data
            </h2>
            <p className='dashboard-empty-text'>Try reloading the page</p>
          </div>
        </div>
      </div>
    );
  }

  if (
    opportunities.hot.length === 0 &&
    opportunities.warm.length === 0 &&
    opportunities.contacted.length === 0
  ) {
    return (
      <div className='dashboard-empty'>
        <FlameIcon className='dashboard-empty-icon' />
        <div>
          <h2 className='dashboard-empty-title'>
            Find and close your most interested prospects
          </h2>
          <p className='dashboard-empty-text'>
            Use opens, clicks, and views to track your most engaged prospects,
            <br />
            then close them when they're most interested.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className='dashboard-opportunities'>
      <h1>Opportunity</h1>
      {clickTrackingDisabled ||
      websiteVisitDisabled ||
      whatsappStatus !== 'number_is_assigned' ? (
        <div className='warnings-area'>
          {clickTrackingDisabled && (
            <a href='/campaigns' className='warning-card always-has-flex-start'>
              <WarningComputerIcon />
              <div className='warning-content'>
                <h2>You're missing out on opportunities</h2>
                <p>Track clicks in your emails by adding links</p>
              </div>
              <div className='warning-arrow'>
                <WarningRightArrowIcon />
              </div>
            </a>
          )}
          {websiteVisitDisabled && (
            <a href='/settings/web-tracking' className='warning-card'>
              <WarningLinkIcon />
              <div className='warning-content'>
                {!clickTrackingDisabled && (
                  <h2>You're missing out on opportunities</h2>
                )}
                <p>Setup website tracking to find more opportunities</p>
              </div>
              <div className='warning-arrow'>
                <WarningRightArrowIcon />
              </div>
            </a>
          )}
          {whatsappStatus === 'no_number' && (
            <a href='/settings/account-links' className='warning-card whatsapp'>
              <WhatsappWarningIcon />
              <div className='warning-content'>
                <p>
                  Others connect Whatsapp to receive real-time opportunities
                </p>
              </div>
              <div className='warning-arrow'>
                <WarningRightArrowIcon />
              </div>
            </a>
          )}
          {whatsappStatus === 'has_number' && (
            <a href='/profiles' className='warning-card whatsapp'>
              <WhatsappWarningIcon />
              <div className='warning-content'>
                <p>Assign WhatsApp to receive real-time opportunities</p>
              </div>
              <div className='warning-arrow'>
                <WarningRightArrowIcon />
              </div>
            </a>
          )}
        </div>
      ) : (
        <></>
      )}

      {opportunities.hot.length > 0 || opportunities.warm.length > 0 ? (
        <div className='opportunities-section'>
          {opportunities.hot.length > 0 && (
            <>
              {opportunities.hot.map((opp) => (
                <OpportunityCard
                  key={opp.org?.id || `group-${opp.prospects[0].id}`}
                  opportunity={opp}
                />
              ))}
            </>
          )}

          {opportunities.warm.length > 0 && (
            <>
              {opportunities.warm.map((opp) => (
                <OpportunityCard
                  key={opp.org?.id || `group-${opp.prospects[0].id}`}
                  opportunity={opp}
                />
              ))}
            </>
          )}
        </div>
      ) : (
        <></>
      )}

      {opportunities.contacted.length > 0 && (
        <>
          <h2 className='opportunities-section-title'>Completed</h2>
          {opportunities.contacted.map((opp) => (
            <OpportunityCard
              key={opp.org?.id || `group-${opp.prospects[0].id}`}
              opportunity={opp}
              completedView={opp?.activityDoneTime}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default DashboardOpportunities;
