import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../api';
import getSymbolFromCurrency from 'currency-symbol-map';
import './dashboard/dashboard.scss';
import './dashboard/successful-engagements.scss';

import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CampaignsList from './dashboard/CampaignsList';
import DashboardOpportunities from './dashboard/DashboardOpportunities';
import EngagementList from './dashboard/EngagementList';
import SuccessfulEngagements from './dashboard/SuccessfulEngagements';
import ReplyList from './dashboard/ReplyList';

const Dashboard = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [conversationStats, setConversationStats] = useState(null);
  const [prospectCount, setProspectCount] = useState(null);
  const [emailCount, setEmailCount] = useState(null);
  const [linkClickCount, setLinkClickCount] = useState(null);
  const [openCount, setOpenCount] = useState(null);
  const [websiteVisitCount, setWebsiteVisitCount] = useState(null);
  const [trackingChecks, setTrackingChecks] = useState(null);
  const [pipelineValue, setPipelineValue] = useState(null);
  const [opportunityData, setOpportunityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedInterval, setSelectedInterval] = useState(7);
  const [selectedDatasets, setSelectedDatasets] = useState(['prospects']);
  const [currentView, setCurrentView] = useState('default');
  const hasShownLoadingRef = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!hasShownLoadingRef.current) {
        setLoading(true);
      }

      const token = await getAccessTokenSilently();
      try {
        const fetchConversations = api.get(
          `/conversations?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchProspectCount = api.get(
          `/dashboard/prospect-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchEmailCount = api.get(
          `/dashboard/email-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchLinkClickCount = api.get(
          `/dashboard/link-click-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchWebsiteVisitCount = api.get(
          `/dashboard/website-visit-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchOpenCount = api.get(
          `/dashboard/open-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchOpportunities = api.get(
          `/dashboard/opportunities-created?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchPipelineValue = api.get(`/dashboard/value`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const checkForDomains = async () => {
          try {
            const response = await api.get('/tracking-check/check', {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setTrackingChecks(response.data);
          } catch (error) {
            if (error.response && error.response.status === 404) {
              setTrackingChecks({ domain: null, emailLinks: 0 });
            } else {
              console.error('Error checking domain:', error);
              throw error;
            }
          }
        };

        const [
          responseConversations,
          responseProspectCount,
          responseEmailCount,
          responseLinkClickCount,
          responseWebsiteVisitCount,
          responseOpenCount,
          responsePipelineValue,
          responseOpportunities,
        ] = await Promise.all([
          fetchConversations,
          fetchProspectCount,
          fetchEmailCount,
          fetchLinkClickCount,
          fetchWebsiteVisitCount,
          fetchOpenCount,
          fetchPipelineValue,
          fetchOpportunities,
        ]).catch(() => {});

        await checkForDomains();

        setConversationStats(responseConversations.data);
        setProspectCount(responseProspectCount.data);
        setEmailCount(responseEmailCount.data);
        setLinkClickCount(responseLinkClickCount.data);
        setWebsiteVisitCount(responseWebsiteVisitCount.data);
        setOpenCount(responseOpenCount.data);
        setPipelineValue(responsePipelineValue.data);
        setOpportunityData(responseOpportunities.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
        hasShownLoadingRef.current = true;
      }
    };

    fetchData();

    const fetchInterval = setInterval(fetchData, 1000 * 60 * 5); // Refresh stats five minutes

    return () => clearInterval(fetchInterval); // Clear interval on component unmount
  }, [getAccessTokenSilently, selectedInterval]);

  const handleIntervalChange = (newInterval) => {
    setSelectedInterval(newInterval);
  };

  const toggleDataset = (dataset) => {
    setSelectedDatasets((prevDatasets) =>
      prevDatasets.includes(dataset)
        ? prevDatasets.filter((d) => d !== dataset)
        : [...prevDatasets, dataset],
    );
  };

  const getTotalProspects = () => {
    if (!prospectCount) return 0;
    return Object.keys(prospectCount.breakdown).reduce(
      (prev, curr) => prev + prospectCount.breakdown[curr],
      0,
    );
  };

  const getNewProspects = () => {
    if (!prospectCount) return 0;
    return prospectCount.new_prospects;
  };

  const getTotalEmailsSent = () => {
    if (!emailCount) return 0;
    return Object.keys(emailCount.breakdown).reduce(
      (prev, curr) => prev + emailCount.breakdown[curr],
      0,
    );
  };

  const getLinkClicks = () => {
    if (!linkClickCount) return 0;
    return linkClickCount.new_clicks;
  };

  const getWebsiteVisits = () => {
    if (!websiteVisitCount) return 0;
    return websiteVisitCount.new_visits;
  };

  const getNewEmailsSent = () => {
    if (!emailCount) return 0;
    return emailCount.new_emails;
  };

  const getRepliesCount = () => {
    if (!conversationStats) return 0;
    return Object.keys(conversationStats.breakdown).reduce((prev, curr) => {
      const obj = conversationStats.breakdown[curr];
      return (
        prev + obj?.replied + obj?.booked + obj?.success + obj?.fail_with_reply
      );
    }, 0);
  };

  const getOpenCount = () => {
    if (!openCount) return 0;
    return openCount.new_opens;
  };

  const getTotalConversations = () => {
    return Object.keys(conversationStats.breakdown).reduce((prev, curr) => {
      const obj = conversationStats.breakdown[curr];
      return (
        prev +
        obj?.replied +
        obj?.booked +
        obj?.fail_with_reply +
        obj?.fail_without_reply +
        obj?.success +
        obj?.contacted +
        obj?.viewed
      );
    }, 0);
  };

  const getRepliesPercentage = () => {
    if (!conversationStats) return 0;
    const totalConversations = getTotalConversations();
    const totalReplies = getRepliesCount();
    return totalConversations > 0
      ? ((totalReplies / totalConversations) * 100).toFixed(2)
      : 0;
  };

  const getCallsBooked = () => {
    if (!conversationStats) return 0;
    return Object.keys(conversationStats.breakdown).reduce((prev, curr) => {
      const obj = conversationStats.breakdown[curr];
      return prev + obj?.booked + obj?.success;
    }, 0);
  };

  const getCallsBookedPercentage = () => {
    if (!conversationStats) return 0;
    const totalConversations = getTotalConversations();
    const totalBooked =
      getCallsBooked() + (conversationStats.success?.all_time || 0);
    return totalConversations > 0
      ? ((totalBooked / totalConversations) * 100).toFixed(1)
      : 0;
  };

  const getOpportunityCount = () => {
    if (!opportunityData) return 0;
    return opportunityData.count;
  };

  const getOpportunityValue = () => {
    if (!opportunityData?.value) return { amount: 0, currency: 'USD' };
    return opportunityData.value;
  };

  const getOpportunityCountChange = () => {
    if (!opportunityData?.count_prev_period) return '-';
    const current = opportunityData.count;
    const prev = opportunityData.count_prev_period;
    if (prev === 0) return '-';
    const change = ((current - prev) / prev) * 100;
    return change.toFixed(0);
  };

  const getOpportunityValueChange = () => {
    if (!opportunityData?.value?.amount_prev_period) return '-';
    const current = opportunityData.value.amount;
    const prev = opportunityData.value.amount_prev_period;
    if (prev === 0) return '-';
    const change = ((current - prev) / prev) * 100;
    return change.toFixed(0);
  };

  const formatCurrency = (value) => {
    const symbol = getSymbolFromCurrency(value.currency);
    const amount = value.amount;

    if (amount >= 1000) {
      return `${symbol}${(amount / 1000).toFixed(1)}K`;
    }

    if (!symbol || !amount) {
      return '-';
    }

    return `${symbol}${amount.toLocaleString()}`;
  };

  const clickTrackingDisabled =
    trackingChecks && trackingChecks.emailLinks === 0;
  const websiteVisitDisabled = trackingChecks && !trackingChecks.domain;

  return (
    <div className='page-wrapper new-dashboard dashboard-page'>
      <div className='page-inner-wrapper main-dashboard'>
        <div className='time-navigator'>
          <h1>Deal Dashboard</h1>
          <div className='chart-management'>
            <div className='selected-and-toggle'>
              <div className='interval-toggle'>
                <div
                  className={selectedInterval === 7 ? 'active' : ''}
                  onClick={() => handleIntervalChange(7)}
                >
                  7 Days
                </div>
                <div
                  className={selectedInterval === 30 ? 'active' : ''}
                  onClick={() => handleIntervalChange(30)}
                >
                  30 Days
                </div>
                <div
                  className={selectedInterval === 'all' ? 'active' : ''}
                  onClick={() => handleIntervalChange('all')}
                >
                  All Time
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='dashboard-columns'>
          <div className='dashboard-column left-column'>
            <div className='dream-top-boxes'>
              <div className='box-holder'>
                <div
                  className={`top-box prospects ${selectedDatasets.includes('prospects') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
                  onClick={() => toggleDataset('prospects')}
                >
                  <h5>New Prospects</h5>
                  <p>
                    {getTotalProspects()}
                    {selectedInterval === 7 && (
                      <span>+ {getNewProspects()}</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className='lower-wrapper'>
              <CampaignsList />
            </div>
          </div>

          <div className='dashboard-column middle-column'>
            <div className='dream-top-boxes'>
              <div className='box-holder'>
                <div
                  className={`top-box opportunities ${loading ? 'loading' : ''}`}
                >
                  <h5>Opportunities Created</h5>
                  <p>
                    {getOpportunityCount()}
                    {selectedInterval !== 'all' && (
                      <span
                        className={
                          getOpportunityCountChange() >= 0
                            ? 'positive'
                            : 'negative'
                        }
                      >
                        {getOpportunityCountChange() === '-'
                          ? '-'
                          : `${getOpportunityCountChange() >= 0 ? '+' : ''}${getOpportunityCountChange()}%`}
                      </span>
                    )}
                  </p>
                </div>
                <div className={`top-box value ${loading ? 'loading' : ''}`}>
                  <h5>Opportunity Value</h5>
                  <p>
                    {formatCurrency(getOpportunityValue())}
                    {selectedInterval !== 'all' && (
                      <span
                        className={
                          getOpportunityValueChange() >= 0
                            ? 'positive'
                            : 'negative'
                        }
                      >
                        {getOpportunityValueChange() === '-'
                          ? '-'
                          : `${getOpportunityValueChange() >= 0 ? '+' : ''}${getOpportunityValueChange()}%`}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className='lower-wrapper'>
              <DashboardOpportunities
                clickTrackingDisabled={clickTrackingDisabled}
                websiteVisitDisabled={websiteVisitDisabled}
              />
            </div>
          </div>

          <div className='dashboard-column right-column'>
            <div className='dream-top-boxes'>
              <div className='box-holder'>
                <div
                  className={`top-box replies ${selectedDatasets.includes('replies') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
                  onClick={() => {
                    toggleDataset('replies');
                    setCurrentView('replies');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <h5>Replies</h5>
                  <p>
                    {getRepliesCount()}
                    <span>{getRepliesPercentage()}%</span>
                  </p>
                </div>
                <div
                  className={`top-box success ${selectedDatasets.includes('success') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
                  onClick={() => {
                    toggleDataset('success');
                    setCurrentView('default');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <h5>Success</h5>
                  <p>
                    {getCallsBooked()}
                    <span>{getCallsBookedPercentage()}%</span>
                  </p>
                </div>
                <div
                  className={`top-box pipeline ${selectedDatasets.includes('pipeline') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
                  onClick={() => {
                    toggleDataset('pipeline');
                    setCurrentView('default');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <h5>Pipeline</h5>
                  <p>
                    {pipelineValue
                      ? (() => {
                          const period = pipelineValue.periods.find(
                            (p) =>
                              p.period ===
                              (selectedInterval === 30
                                ? '30'
                                : selectedInterval === 7
                                  ? '7'
                                  : 'all'),
                          );
                          const value = period?.value;

                          const symbol =
                            getSymbolFromCurrency(pipelineValue.currency) ||
                            pipelineValue.currency;

                          if (!value) return '-';

                          if (value >= 1000000) {
                            return `${symbol}${(value / 1000000).toFixed(1)}M`;
                          } else if (value >= 1000) {
                            return `${symbol}${(value / 1000).toFixed(1)}K`;
                          }
                          return `${symbol}${Math.round(value)}`;
                        })()
                      : '-'}
                  </p>
                </div>
              </div>
            </div>
            <div className='lower-wrapper'>
              {currentView === 'replies' ? (
                <ReplyList selectedInterval={selectedInterval} />
              ) : (
                <>
                  <EngagementList />
                  <SuccessfulEngagements selectedInterval={selectedInterval} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
