import React, { useEffect, useState } from 'react';
import { usePusher } from '../../../contexts/PusherContext'; // Adjust the path if necessary
import { ReactComponent as AddNewCompanyIcon } from './../../../icons/add-new-company.svg';
import { ReactComponent as ErrorIcon } from './../../../icons/error.svg';
import { ReactComponent as SoftProcessingIcon } from './../../../icons/soft-processing.svg';
import './add-new-row.css';

function AddNewRow(params) {
  const [state, setState] = useState('');
  const [completedData, setCompletedData] = useState(null);
  const [error, setError] = useState(null);
  const pusherChannel = usePusher();

  useEffect(() => {
    if (pusherChannel) {
      const manualAddUpdateHandler = (data) => {
        if (data.manual_add_id === params.data.known_org_id) {
          if (data.error) {
            setError(data.error);
            setState('');
          } else {
            setError(null);
            setState(data.state);
            if (data.state === 'COMPLETED') {
              setCompletedData({
                filteredOrg: data.filteredOrg,
                searchedOrg: data.searchedOrg,
              });
            }

            // If we've been sent through a new name, use that.
            if (data.name) {
              params.data.name = data.name;
            }
          }
        }
      };

      pusherChannel.bind('manual_company_add_update', manualAddUpdateHandler);

      return () => {
        pusherChannel.unbind(
          'manual_company_add_update',
          manualAddUpdateHandler,
        );
      };
    }
  }, [pusherChannel, params.data.known_org_id]);

  useEffect(() => {
    if (state === 'COMPLETED' && completedData) {
      params.addManuallyAddedCompanies(completedData, params.data.known_org_id);
    }
  }, [state, completedData, params]);

  const getStatusText = () => {
    if (error) {
      return '';
    }

    const stateText = () => {
      switch (state) {
        case 'SYNCING':
          return `Searching for ${params.data.name}`;
        case 'FINALISING':
          return `Creating necessary research tasks ${params.data.name}`;
        default:
          return `Creating entry for ${params.data.name}`;
      }
    };

    return (
      <div className='status-wrapper'>
        <SoftProcessingIcon />
        {stateText()}
      </div>
    );
  };

  const rowClass = error ? 'add-new-loading-row error' : 'add-new-loading-row';

  return (
    <div className={rowClass}>
      <div className='card-left'>
        {error ? <ErrorIcon /> : <AddNewCompanyIcon />}
        {error
          ? `Error adding ${params.data.name} (${error})`
          : params.data.name}{' '}
      </div>
      <span className='status-text'>{getStatusText()}</span>
    </div>
  );
}

export default AddNewRow;
