import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import { parseISO, differenceInMinutes, differenceInHours } from 'date-fns';
import ConversationModal from '../misc/ConversationModal';
import FakePic from '../feed/items/FakePic';
import './engagements.css';
import { ReactComponent as ConversationEmptyIcon } from '../../icons/conversation-empty.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error-exclamation.svg';

function ReplyList({ selectedInterval }) {
  const [replies, setReplies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [selectedEngagement, setSelectedEngagement] = useState(null);

  const fetchReplies = async () => {
    try {
      setError(false);
      const token = await getAccessTokenSilently();
      const response = await api.get('/conversations/states', {
        params: {
          state: 'REPLIES',
          days: selectedInterval,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      // Sort replies by updated_at in descending order and filter out FAIL_WITHOUT_REPLY
      const sortedReplies = response.data.conversations
        .filter(reply => reply.status !== 'FAIL_WITHOUT_REPLY')
        .sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
        );

      setReplies(sortedReplies);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching replies:', error);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReplies();
  }, [selectedInterval]);

  const getTimeDisplay = (date) => {
    const parsed = parseISO(date);
    const minutesDiff = differenceInMinutes(new Date(), parsed);
    const hoursDiff = differenceInHours(new Date(), parsed);

    if (minutesDiff < 60) {
      return `${minutesDiff}m`;
    } else if (hoursDiff < 24) {
      return `${hoursDiff}h`;
    } else {
      return `${Math.floor(hoursDiff / 24)}d`;
    }
  };

  const getStatusClass = (status) => {
    if (status === 'BOOKED' || status === 'SUCCESS') {
      return 'green';
    }
    if (
      status === 'FAIL' ||
      status === 'FAIL_WITH_REPLY' ||
      status === 'FAIL_WITHOUT_REPLY'
    ) {
      return 'red';
    }
    return 'amber';
  };

  const formatStatus = (status) => {
    return status
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  if (loading) {
    return (
      <div className='engagement-list'>
        <h1>Replies</h1>
        <div className='dash-loading-state'></div>
      </div>
    );
  }
  if (error) {
    return (
      <div className='engagement-list'>
        <h1>Replies</h1>
        <div className='dashboard-empty'>
          <ErrorIcon className='dashboard-error-icon' />
          <div>
            <h2 className='dashboard-empty-title'>
              We're having trouble getting your data
            </h2>
            <p className='dashboard-empty-text'>Try reloading the page</p>
          </div>
        </div>
      </div>
    );
  }

  if (replies.length === 0) {
    return (
      <div className='engagement-list'>
        <h1>Replies</h1>
        <div className='dashboard-empty'>
          <ConversationEmptyIcon className='dashboard-empty-icon' />
          <div>
            <h2 className='dashboard-empty-title'>
              Keep track of your replies
            </h2>
            <p className='dashboard-empty-text'>
              Start your campaigns and we'll show any communications from your
              prospects here. View replies, escalations, and successful
              conversations.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='engagement-list'>
        <h1>Replies</h1>
        <div className='engagement-area-wrapper'>
          <div className='section'>
            <h2>Here are your replies</h2>
            <div className='action-items-wrapper'>
              {replies.map((reply) => (
                <div
                  key={reply.id}
                  className='action-item'
                  onClick={() => setSelectedEngagement(reply)}
                >
                  <FakePic
                    url={reply.logo_url}
                    name={reply.company_name}
                    size={32}
                    type='org'
                  />
                  <div>
                    <h3>{reply.company_name}</h3>
                    <p>{getTimeDisplay(reply.updated_at)}</p>
                  </div>
                  <span className={`time ${getStatusClass(reply.status)}`}>
                    {formatStatus(reply.status)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {selectedEngagement && (
        <ConversationModal
          show
          id={selectedEngagement.id}
          onHide={() => setSelectedEngagement(null)}
        />
      )}
    </>
  );
}

export default ReplyList;
