import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import './inbox.css';
import { Form } from 'react-bootstrap';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import FakePic from '../feed/items/FakePic';
import ConversationModal from '../misc/ConversationModal';
import { usePusher } from '../../contexts/PusherContext';

const Inbox = ({ campaignId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();

  const [stats, setStats] = useState({
    initial_messages: 0,
    follow_ups: 0,
    opens: 0,
    replies: 0,
    fail: 0,
    booked: 0,
  });

  const [conversations, setConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [showOnlyReplies, setShowOnlyReplies] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [showModal, setShowModal] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingPostUpdate, setLoadingPostUpdate] = useState(false);

  const fetchInboxData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/campaign-overview/inbox/${campaignId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setStats(response.data.stats);
      setConversations(response.data.conversations);
      setInitialLoading(false);
      setLoadingPostUpdate(false);
    } catch (error) {
      console.error('Error fetching inbox data:', error);
      setInitialLoading(false);
      setLoadingPostUpdate(false);
    }
  };

  useEffect(() => {
    if (!pusherChannel || !campaignId) return;
    pusherChannel.bind('oagent_status_update', (data) => {
      if (parseInt(data.oagentId, 10) === parseInt(campaignId, 10)) {
        setLoadingPostUpdate(true);
        fetchInboxData();
      }
    });
    return () => {
      pusherChannel.unbind('oagent_status_update');
    };
  }, [campaignId, pusherChannel, campaignId]);

  useEffect(() => {
    fetchInboxData();
  }, [campaignId]);

  useEffect(() => {
    const results = conversations.filter((conversation) => {
      if (
        showOnlyReplies &&
        ['CONTACTED', 'VIEWED'].includes(conversation.status)
      ) {
        return false;
      }
      if (selectedFilter === 'All') {
        return true;
      }
      return conversation.status === selectedFilter;
    });
    setFilteredConversations(results);
  }, [conversations, showOnlyReplies, selectedFilter]);

  const handleConversationClick = (id) => {
    setSelectedConversation(id);
    setShowModal(true);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setShowOnlyReplies(e.target.checked);
  };

  return (
    <div
      className={`inbox-inner-wrapper ${initialLoading ? 'first-loading' : ''}`}
    >
      <div className='inbox-header'>
        <h4>Inbox</h4>
        <div className='inbox-filters'>
          <div className='checkbox-wrapper'>
            <input
              type='checkbox'
              className='sexy-checkbox'
              id='show-only-replies'
              checked={showOnlyReplies}
              onChange={handleCheckboxChange}
            />
            <label htmlFor='show-only-replies'>Show only replies</label>
          </div>
          {/* <Form.Group>
            <Form.Select value={selectedFilter} onChange={handleFilterChange}>
              <option value='All'>All message types</option>
              <option value='REPLIED'>Replies</option>
              <option value='VIEWED'>Opened</option>
              <option value='FAIL'>Unsuccessful</option>
              <option value='BOOKED'>Meeting Booked</option>
              <option value='SUCCESS'>Successful</option>
            </Form.Select>
          </Form.Group> */}
        </div>
      </div>
      <div className='inbox-stats'>
        <div className='stat'>
          <span className='stat-label'>Emails sent</span>
          <span className='stat-number'>
            {parseInt(stats.follow_ups, 10) +
              parseInt(stats.initial_messages, 10)}
          </span>
        </div>
        <div className='stat'>
          <span className='stat-label'>Prospect open rate</span>
          <span className='stat-number'>{stats.conversation_open_rate}%</span>
        </div>
        <div className='stat'>
          <span className='stat-label'>Email open rate</span>
          <span className='stat-number'>{stats.open_rate}%</span>
        </div>
        <div className='stat'>
          <span className='stat-label'>Email bounce rate</span>
          <span className='stat-number'>{stats.bounce_rate}%</span>
        </div>
        <div className='stat'>
          <span className='stat-label'>Link click rate</span>
          <span className='stat-number'>{stats.link_click_rate}%</span>
        </div>
      </div>
      <div className='inbox-conversations'>
        {!initialLoading &&
          filteredConversations
            .sort((a, b) => {
              return a.last_response_time > b.last_response_time ? -1 : 1;
            })
            .map((conversation) => (
              <div
                key={conversation.id}
                className='conversation'
                onClick={() => handleConversationClick(conversation.id)}
              >
                <div className='conversation-header'>
                  <div className='counts-wrapper'>
                    <EmailIcon />
                    <div>
                      <span className='outbound-count'>
                        {conversation.email_count === 1
                          ? '1 email'
                          : `${conversation.email_count} emails`}
                      </span>
                    </div>
                  </div>
                  <span className='last-response-time'>
                    {conversation.last_response ? `Last reply` : 'Contacted'}{' '}
                    {formatDistanceToNow(
                      parseISO(
                        conversation.last_response_time ||
                          conversation.last_message_time,
                      ),
                      {
                        addSuffix: true,
                      },
                    )}
                  </span>
                </div>
                <div className='lower'>
                  <div className='prospects'>
                    {conversation.prospects.map((prospect) => (
                      <div key={prospect.id} className='prospect'>
                        <div className='prospect-info-wrapper'>
                          <FakePic url={prospect.logo_url} />
                          <div className='prospect-info'>
                            <div className='prospect-name-org'>
                              <span className='prospect-name'>
                                {prospect.name}
                              </span>
                              <span>•</span>
                              <span className='org-name'>
                                {prospect.known_org.name}
                              </span>
                            </div>
                            <div className='prospect-email'>
                              {prospect.email}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='conversation-status'>
                    <span
                      className={`status-label ${conversation.status.toLowerCase()}`}
                    >
                      {conversation.status.slice(0, 1) +
                        conversation.status.slice(1).toLowerCase()}
                    </span>
                  </div>
                </div>
              </div>
            ))}
        {initialLoading && (
          <>
            <div className='fake-conversation'>
              <div className='fake-header'></div>
              <div className='fake-body'>
                <div className='fake-prospect-pic'></div>
                <div className='fake-block'></div>
              </div>
            </div>
            <div className='fake-conversation'>
              <div className='fake-header'></div>
              <div className='fake-body'>
                <div className='fake-prospect-pic'></div>
                <div className='fake-block'></div>
              </div>
            </div>
            <div className='fake-conversation'>
              <div className='fake-header'></div>
              <div className='fake-body'>
                <div className='fake-prospect-pic'></div>
                <div className='fake-block'></div>
              </div>
            </div>
            <div className='fake-conversation'>
              <div className='fake-header'></div>
              <div className='fake-body'>
                <div className='fake-prospect-pic'></div>
                <div className='fake-block'></div>
              </div>
            </div>
          </>
        )}
        {!initialLoading &&
          conversations.length > 0 &&
          filteredConversations.length === 0 && (
            <div className='empty-state'>
              <EmailIcon />
              <div>
                <h6>No threads</h6>
                <p>There are no threads that matched your filter.</p>
              </div>
            </div>
          )}
        {!initialLoading &&
          conversations.length === 0 &&
          filteredConversations.length === 0 && (
            <div className='empty-state'>
              <EmailIcon />
              <div>
                <h6>No emails</h6>
                <p>
                  Once you start your campaign, emails will begin to appear
                  here.
                </p>
              </div>
            </div>
          )}
      </div>
      <ConversationModal
        show={showModal}
        onHide={() => setShowModal(false)}
        id={selectedConversation}
        idIsScheduledEmail={false}
      />
    </div>
  );
};

export default Inbox;
