import React, { useEffect, useState } from 'react';
import DreamModalInner from '../../misc/DreamModalInner';
import DreamSideModal from '../../misc/DreamSideModal';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import DreamSideModalLoader from '../../misc/DreamSideModalLoader';
import api from '../../../api';

import './research-related-prospect-search-modal.css';

function GuidanceProspectSearchModal({
  show,
  handleClose,
  oagentId,
  refreshProspectSearch,
  editMode,
  prospectSearchId,
  metadata,
}) {
  const [guidance, setGuidance] = useState('');
  const [numProspects, setNumProspects] = useState();

  const [datapoints, setDatapoints] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [associatedDatapoints, setAssociatedDatapoints] = useState([]);
  const [associatedAdditionalData, setAssociatedAdditionalData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setGuidance(metadata?.guidance);
    setNumProspects(metadata?.num_prospects || 3);
    setAssociatedDatapoints(metadata?.datapoints || []);
    setAssociatedAdditionalData(metadata?.additional_data_keys || []);
  }, [metadata, editMode, oagentId]);

  const handleSaveSettings = async () => {
    setSaving(true);
    const httpMethod = editMode && prospectSearchId ? 'put' : 'post';

    try {
      const token = await getAccessTokenSilently();
      const endpoint =
        editMode && prospectSearchId
          ? `/oagents/${oagentId}/prospect-search/${prospectSearchId}`
          : `/oagents/${oagentId}/prospect-search`;

      await api[httpMethod](
        endpoint,
        {
          type: 'research_related',
          metadata: {
            guidance,
            num_prospects: numProspects,
            datapoints: associatedDatapoints,
            additional_data_keys: associatedAdditionalData,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      refreshProspectSearch();

      setSaving(false);
      handleClose();
    } catch (error) {
      console.error(
        `Error saving prospect search (${httpMethod.toUpperCase()}):`,
        error,
      );
      setSaving(false);
      setError('Failed to save settings. Please try again.');
    }
  };

  useEffect(() => {
    const fetchDatapoints = async () => {
      try {
        const token = await getAccessTokenSilently();

        // Fetch all research points from the user's company
        const allDatapointsResponse = await api.get(
          `/analyst/research-points`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        const datapoints = allDatapointsResponse.data.filter(
          (dp) => dp.type === 'datapoint',
        );
        setDatapoints(datapoints);

        // Fetch additional data keys
        const additionalDataKeysResponse = await api.get(
          `/oagents/${oagentId}/additional-data-options`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setAdditionalData(additionalDataKeysResponse.data.uniqueKeys);
      } catch (error) {
        console.error('Error fetching datapoints:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDatapoints();
  }, [getAccessTokenSilently, oagentId]);

  const isDatapointAssociated = (datapointId) =>
    associatedDatapoints.some((assoc) => assoc === datapointId);

  const isAdditionalDataAssociated = (key) =>
    associatedAdditionalData.some((assoc) => assoc === key);

  const handleToggleAdditionalData = (key) => {
    setAssociatedAdditionalData((aD) => {
      if (isAdditionalDataAssociated(key)) {
        return aD.filter((x) => x !== key);
      } else {
        return [...aD, key];
      }
    });
  };

  const handleToggleDatapoint = (id) => {
    console.log(id, associatedDatapoints);
    setAssociatedDatapoints((dps) => {
      if (isDatapointAssociated(id)) {
        return dps.filter((x) => x !== id);
      } else {
        return [...dps, id];
      }
    });
  };

  if (saving || loading) {
    return (
      <DreamSideModal show={show} onHide={handleClose}>
        <DreamModalInner>
          <DreamSideModalLoader>
            {saving ? 'Saving Search' : 'Loading Data'}
          </DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} handleClose={handleClose}>
      <DreamModalInner>
        <DreamModalInner.Header>
          Research related prospect search
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='dream-side-modal-form research-related-prospect-search'>
            <div className='top-description'>
              <p>
                Select datapoints that mention the people you want to add. When
                you run your search we’ll retrieve that person’s profile and
                contact information.
              </p>
              <p>
                We recommend adding an additional search type to find results if
                the datapoint you’ve selected doesn’t exist for this company, or
                the name can’t be matched to a profile.
              </p>
            </div>
            <Form.Group>
              <Form.Label>Select datapoints to reference</Form.Label>
              <div className='datapoint-pills'>
                {additionalData.map((key) => (
                  <div
                    className={`pill ${isAdditionalDataAssociated(key) ? 'active' : ''}`}
                    key={key}
                    onClick={() => handleToggleAdditionalData(key)}
                  >
                    {key}
                  </div>
                ))}
                {datapoints.map((datapoint) => (
                  <div
                    className={`pill ${isDatapointAssociated(datapoint.id) ? 'active' : ''}`}
                    key={datapoint.id}
                    onClick={() => handleToggleDatapoint(datapoint.id)}
                  >
                    {datapoint.name}
                  </div>
                ))}
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Guidance</Form.Label>
              <Form.Text>
                What should your agent look for in these datapoints?
              </Form.Text>
              <Form.Control
                as='textarea'
                rows={3}
                value={guidance}
                onChange={(e) => setGuidance(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='range-flex'>
              <Form.Label>Maximum prospects to return per company</Form.Label>
              <Form.Text>
                Each prospect added to a campaign costs 1 credit.
              </Form.Text>
              <RangeSlider
                value={numProspects}
                onChange={(e) => setNumProspects(e.target.value)}
                min={1}
                max={20}
                tooltipLabel={(currentValue) => `${currentValue}`}
                variant='info'
                size='lg'
              />
              <div className='indicator-line'>
                <span>1</span>
                <span>20</span>
              </div>
            </Form.Group>
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button onClick={() => handleSaveSettings()}>Save</Button>
          <Button variant='outline-primary' onClick={() => handleClose()}>
            Close
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default GuidanceProspectSearchModal;
