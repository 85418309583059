import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../api';
import './admin-dashboard.scss';

const AdminDashboard = () => {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchCompanyAnalytics = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/admin-dashboard/company-analytics', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCompanies(response.data);
        setError(null);
      } catch (err) {
        setError(
          err.response?.data?.error || 'Failed to fetch company analytics',
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyAnalytics();
  }, [getAccessTokenSilently]);

  const formatCurrency = (value, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
  };

  const renderPercentageChange = (current, previous) => {
    const change = calculatePercentageChange(current, previous);
    const isPositive = change > 0;
    const isNeutral = change === 0;

    if (isNeutral) return null;

    return (
      <div
        className={`percentage-change ${isPositive ? 'positive' : 'negative'}`}
      >
        {isPositive ? '↑' : '↓'} {Math.abs(change).toFixed(1)}%
      </div>
    );
  };

  if (loading) {
    return <div className='admin-dashboard loading'>Loading...</div>;
  }

  if (error) {
    return <div className='admin-dashboard error'>{error}</div>;
  }

  return (
    <div className='admin-dashboard'>
      <h1>Company Analytics</h1>
      <div className='company-grid'>
        {companies.map((company) => (
          <div key={company.company_id} className='company-card'>
            <h2>{company.company_name}</h2>
            <div className='stats'>
              <div className='stat'>
                <label>Emails Sent (7d)</label>
                <div className='value-container'>
                  <value>{company.emails_sent.current}</value>
                  {renderPercentageChange(
                    company.emails_sent.current,
                    company.emails_sent.previous,
                  )}
                </div>
              </div>
              <div className='stat'>
                <label>Opportunities (7d)</label>
                <div className='value-container'>
                  <value>{company.opportunities.current}</value>
                  {renderPercentageChange(
                    company.opportunities.current,
                    company.opportunities.previous,
                  )}
                </div>
              </div>
              <div className='stat'>
                <label>Pipeline Value (7d)</label>
                <div className='value-container'>
                  <value>
                    {formatCurrency(
                      company.pipeline_value.current,
                      company.currency,
                    )}
                  </value>
                  {renderPercentageChange(
                    company.pipeline_value.current,
                    company.pipeline_value.previous,
                  )}
                </div>
              </div>
              <div className='stat'>
                <label>Opportunity Value (7d)</label>
                <div className='value-container'>
                  <value>
                    {formatCurrency(
                      company.opportunity_value.current,
                      company.currency,
                    )}
                  </value>
                  {renderPercentageChange(
                    company.opportunity_value.current,
                    company.opportunity_value.previous,
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
