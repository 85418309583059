import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { usePusher } from '../../contexts/PusherContext'; // Import the Pusher context hook

const WSStatusUpdateReceiver = ({
  notificationType,
  onNotificationReceived,
  children,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher(); // Get the Pusher channel

  useEffect(() => {
    if (pusherChannel) {
      const notificationHandler = (update) => {
        onNotificationReceived(update);
      };

      pusherChannel.bind(notificationType, notificationHandler);

      // Unbind events on cleanup
      return () => {
        pusherChannel.unbind(notificationType, notificationHandler);
      };
    }
  }, [
    pusherChannel,
    notificationType,
    getAccessTokenSilently,
    onNotificationReceived,
  ]);

  return <>{children}</>;
};

export default WSStatusUpdateReceiver;
