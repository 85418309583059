import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from '../api';
import functionManifests from '../utils/function-manifests';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faArrowLeft,
  faPlus,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';

import Agent from './Agent';
import OrgList from './funnel/OrgList';

import { ReactComponent as EditIcon } from '../icons/edit.svg';
import { ReactComponent as MaximizeIcon } from '../icons/maximize.svg';
import { ReactComponent as MinimizeIcon } from '../icons/minimize.svg';
import { ReactComponent as TrashIcon } from '../icons/trash.svg';
import { ReactComponent as ExclusionIcon } from '../icons/exclusion.svg';
import { ReactComponent as SettingsIcon } from '../icons/settings.svg';

import FunnelRunModal from './funnel/FunnelRunModal';

import CongratsModal from './misc/CongratsModal.js';

import StageEditModal from './funnel/StageEditModal.js';
import ResearchPointSelectorModal from './funnel/ResearchPointSelectorModal.js';
import ResearchPointChooseModal from './funnel/ResearchPointChooseModal.js';
import AddColumnModal from './funnel/AddColumnModal.js';
import FurtherUploader from './funnel/FurtherUploader.js';

import DownloadCSVButton from './funnel/DownloadCSVButton.js';
import CreditCost from './funnel/CreditCost.js';
import ScheduleFunnelButton from './funnel/ScheduleFunnelButton.js';

import { useSidebar } from '../contexts/SidebarContext';
import { usePusher } from '../contexts/PusherContext';

import './funnel/funnel.css';
import { marked } from 'marked';
import AdColumnConfigModal from './funnel/AdColumnConfigModal.js';
import FunnelCRMSettingsModal from './funnel/FunnelCRMSettingsModal.js';
import FunnelSearchHistory from './funnel/FunnelSearchHistory.js';

function Funnel() {
  const { getAccessTokenSilently } = useAuth0();
  const { setSidebarVisible } = useSidebar();
  const pusherChannel = usePusher(); // Get the Pusher channel

  const [fullscreen, setFullscreen] = useState(false);
  const [funnel, setFunnel] = useState(null);
  const [funnelLoading, setFunnelLoading] = useState(true);
  const [orgsLoading, setOrgsLoading] = useState(false);
  const [orgs, setOrgs] = useState({ filtered: [], searched: [] });
  const [stages, setStages] = useState([]);
  const [highlightStages, setHighlightStages] = useState(false);
  const [funnelRunStatus, setFunnelRunStatus] = useState(null);
  const [currentOrgView, setCurrentOrgView] = useState('filtered');
  const [firstStagesLoad, setFirstStagesLoad] = useState(false);
  const [showCrmSettingsModal, setShowCrmSettingsModal] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [canReviewFunnels, setCanReviewFunnels] = useState(false);
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [showResearchPointSelectorModal, setShowResearchPointSelectorModal] =
    useState(false);
  const [showResearchPointChooser, setShowResearchPointChooser] =
    useState(false);
  const [researchPointType, setResearchPointType] = useState(null);
  const [stagesLoadAttempted, setStageLoadAttempted] = useState(false);
  const [funnelLoadAttempted, setFunnelLoadAttempted] = useState(false);
  const [researchPoints, setResearchPoints] = useState([]);
  const [downloadData, setDownloadData] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalStage, setEditModalStage] = useState(null);

  const [showRunModal, setShowRunModal] = useState(false);

  const [cellForDisplay, setCellForDisplay] = useState(null);
  const [selectedOrgIds, setSelectedOrgIds] = useState([]);
  const [showAddColumnModal, setShowAddColumnModal] = useState(false);
  const [showAdColumnConfigModal, setShowAdColumnConfigModal] = useState(false);
  const [additionalKeyChanges, setAdditionalKeyChanges] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    return () => {
      setSidebarVisible(true);
    };
  }, []);

  const toggleFullscreen = (value) => {
    setFullscreen(value);
    setSidebarVisible(!value);
  };

  useEffect(() => {
    const checkFunnelReviewPermission = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(`/debug/funnel-runs?funnel_id=${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCanReviewFunnels(true);
      } catch (error) {
        if (error.response?.status === 401) {
          setCanReviewFunnels(false);
        }
      }
    };

    checkFunnelReviewPermission();
  }, [getAccessTokenSilently, id]);

  const fetchFunnel = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${id}/full-page-details`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFunnelLoadAttempted(true);
      setFunnel(response.data);
    } catch (err) {
      console.error('Error fetching funnel details');
    }
  };

  useEffect(() => {
    if (Object.keys(stages).length > 0 && !firstStagesLoad) {
      if (isImport() && isEffectivelyEmpty('company_filter')) {
        setCurrentOrgView('searched');
      }
      setFirstStagesLoad(true);
    }
  }, [stages, firstStagesLoad]);

  const fetchFunnelRunStatus = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnel-run-status/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFunnelRunStatus(response.data.status);
    } catch (err) {
      console.error('Error fetching funnel run status:', err);
    }
  };

  const fetchOrgs = async () => {
    try {
      setOrgsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${id}/orgs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOrgs(response.data.orgs || { filtered: [], searched: [] });
    } catch (err) {
      console.error('Error fetching orgs');
    } finally {
      setOrgsLoading(false);
    }
  };

  const fetchStages = async (highlight = false) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${id}/stages`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStages(response.data.stages);
      setStageLoadAttempted(true);

      if (highlight) {
        setHighlightStages(true);
        setTimeout(() => {
          setHighlightStages(false);
        }, 2000); // highlight for 2 seconds
      }
    } catch (err) {
      console.error('Error fetching stages');
    }
  };

  const handleAdditionalKeyDelete = async (key) => {
    try {
      const token = await getAccessTokenSilently();
      await api.delete(`/funnels/${id}/additional-data-key`, {
        data: { key },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchOrgs();
    } catch (error) {
      console.error('Error deleting additional key:', error);
    }
  };

  const handleDelete = async () => {
    try {
      const token = await getAccessTokenSilently();
      await api.delete(`/funnels/${id}/orgs`, {
        data: { searched_org_ids: selectedOrgIds },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedOrgIds([]); // Clear selection after deletion
      fetchOrgs();
    } catch (error) {
      console.error('Error deleting organizations:', error);
    }
  };

  const runFunnel = async (maxCompanies, start_from, list_of_companies) => {
    try {
      setFunnelRunStatus('processing');
      const token = await getAccessTokenSilently();
      const body = { funnel_id: id, max_companies: maxCompanies, start_from };
      if (list_of_companies) {
        body.list_of_companies = list_of_companies;
      }
      await api.post('/run-funnel', body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchStages();
    } catch (error) {
      console.error('Error running funnel', error);
    } finally {
      fetchFunnelRunStatus();
      fetchFunnel();
    }
  };

  const startOrStop = async () => {
    if (funnelRunStatus !== 'processing') {
      setShowRunModal(true); // Open the modal before starting the funnel
    } else {
      const token = await getAccessTokenSilently();
      api
        .post(
          '/stop-funnel',
          { funnel_id: id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(
          () => fetchFunnelRunStatus(),
          () => fetchFunnel(),
        );
    }
  };

  const handleRemovedOrg = (data) => {
    // Happens when a duplicate is detected
    const { searched_org_id } = data;

    if (data.funnel_id !== parseInt(id, 10)) {
      return;
    }

    setOrgs((org) => {
      // We have to remove the old org because it will have it's row index as searched_org_id and now we are putting in sometihing
      // with a known_org.
      const searched = org.searched.filter((x) => x.id !== searched_org_id);

      return {
        filtered: org.filtered,
        searched,
      };
    });
  };

  const handleSyncedOrg = (data) => {
    const { searched_org_id, new_org } = data;

    // This is useful when CRM and CSV syncs are happening
    // It means we can update in situ orgs.

    if (data.funnel_id !== parseInt(id, 10)) {
      return;
    }

    setOrgs((org) => {
      // We have to remove the old org because it will have it's row index as searched_org_id and now we are putting in sometihing
      // with a known_org.
      const searched = [
        ...org.searched.filter((x) => x.id !== searched_org_id),
        new_org,
      ];

      return {
        filtered: org.filtered,
        searched,
      };
    });
  };

  const newAdditionalDataValueHandler = (data) => {
    const { key, known_org_id, value } = data;

    if (!key || !known_org_id) {
      console.error('Unable to update funnel without key or known_org_id');
      return;
    }

    let shouldUpdateAdKeysInList = false;

    const updateAdditionalData = (orgs) => {
      const updatedOrgs = [...orgs]; // Shallow copy first level array

      const orgIndex = updatedOrgs.findIndex(
        (org) => org.known_org_id === known_org_id,
      );
      if (orgIndex === -1) {
        return updatedOrgs;
      }

      const org = updatedOrgs[orgIndex];
      const ads = Array.isArray(org.additional_data) ? org.additional_data : [];
      const lengthOfAdsBefore = ads.length;

      const adIndex = ads.findIndex((ad) => ad.key === key);

      if (adIndex === -1) {
        // Add new research point
        ads.push({
          key,
          value,
        });
      } else {
        // Update existing research point
        ads[adIndex] = {
          key,
          value,
        };
      }

      if (ads.length !== lengthOfAdsBefore) {
        shouldUpdateAdKeysInList = true;
      }

      updatedOrgs[orgIndex] = {
        ...org,
        additional_data: ads,
      };

      return updatedOrgs;
    };

    setOrgs((prevOrgs) => ({
      filtered: updateAdditionalData(prevOrgs.filtered),
      searched: updateAdditionalData(prevOrgs.searched),
    }));

    if (shouldUpdateAdKeysInList) {
      setAdditionalKeyChanges(!additionalKeyChanges);
    }
  };

  const newResearchValueHandler = (data) => {
    const {
      research_point_id,
      known_org_id,
      value,
      confidence,
      value_context,
    } = data;

    if (!research_point_id || !known_org_id) {
      console.error(
        'Unable to update funnel without research_point_id or known_org_id',
      );
      return;
    }

    const updateResearchPoints = (orgs) => {
      const updatedOrgs = [...orgs]; // Shallow copy first level array

      const orgIndex = updatedOrgs.findIndex(
        (org) => org.known_org_id === known_org_id,
      );
      if (orgIndex === -1) {
        return updatedOrgs;
      }

      const org = updatedOrgs[orgIndex];
      const researchPoints = Array.isArray(org.research_points)
        ? org.research_points
        : [];

      const researchPointIndex = researchPoints.findIndex(
        (rp) => rp.id === research_point_id,
      );

      if (researchPointIndex === -1) {
        // Add new research point
        researchPoints.push({
          id: research_point_id,
          value,
          confidence,
          value_context,
        });
      } else {
        // Update existing research point
        console.log('here', value, researchPointIndex);
        researchPoints[researchPointIndex] = {
          id: research_point_id,
          value,
          confidence,
          value_context,
        };
      }

      updatedOrgs[orgIndex] = {
        ...org,
        research_points: researchPoints,
      };

      return updatedOrgs;
    };

    setOrgs((prevOrgs) => ({
      filtered: updateResearchPoints(prevOrgs.filtered),
      searched: updateResearchPoints(prevOrgs.searched),
    }));
  };

  // Set up Pusher event bindings
  useEffect(() => {
    if (pusherChannel && id) {
      const funnelStatusUpdateHandler = async (data) => {
        if (data && data.funnel_id === parseInt(id, 10)) {
          await fetchFunnelRunStatus();
        }
      };

      const newFilteredOrgHandler = async (data) => {
        const { funnel_id, filtered_org } = data;
        if (funnel_id === parseInt(id, 10)) {
          setOrgs((prevOrgs) => {
            const withAnimation = {
              ...filtered_org,
              animate_in: true,
            };
            return {
              ...prevOrgs,
              filtered: [withAnimation, ...prevOrgs.filtered],
            };
          });
        }
      };

      const newSearchedOrgHandler = async (data) => {
        const { funnel_id, searched_org } = data;
        if (funnel_id === parseInt(id, 10)) {
          setOrgs((prevOrgs) => {
            const withAnimation = {
              ...searched_org,
              animate_in: true,
            };
            return {
              ...prevOrgs,
              searched: [withAnimation, ...prevOrgs.searched],
            };
          });
        }
      };

      pusherChannel.bind('funnel_status_update', funnelStatusUpdateHandler);
      pusherChannel.bind('new_filtered_org', newFilteredOrgHandler);
      pusherChannel.bind('new_searched_org', newSearchedOrgHandler);
      pusherChannel.bind('analyst_value_update', newResearchValueHandler);
      pusherChannel.bind(
        'new_additional_data_value',
        newAdditionalDataValueHandler,
      );
      pusherChannel.bind('synced_org', handleSyncedOrg);
      pusherChannel.bind('removed_org', handleRemovedOrg);

      // Unbind events on cleanup
      return () => {
        pusherChannel.unbind('funnel_status_update', funnelStatusUpdateHandler);
        pusherChannel.unbind('new_filtered_org', newFilteredOrgHandler);
        pusherChannel.unbind('new_searched_org', newSearchedOrgHandler);
        pusherChannel.unbind('analyst_value_update', newResearchValueHandler);
        pusherChannel.unbind(
          'new_additional_data_value',
          newAdditionalDataValueHandler,
        );
        pusherChannel.unbind('synced_org', handleSyncedOrg);
      };
    }
  }, [pusherChannel, id, getAccessTokenSilently]);

  const untether = async (sets) => {
    try {
      const setsLookup = sets.reduce(
        (
          acc,
          { searched_org, known_org_id, research_point_id, research_point },
        ) => {
          const koid = known_org_id || searched_org.known_org_id;
          const rpid = research_point_id || research_point.id;
          if (!acc[koid]) acc[koid] = {};
          acc[koid][rpid] = true;
          return acc;
        },
        {},
      );

      setOrgs((orgs) => {
        const newFiltered = orgs.filtered.map((org) => {
          const matchedSets = setsLookup[org.known_org_id] || {};
          if (Object.keys(matchedSets).length === 0) {
            return org;
          }

          return {
            ...org,
            research_points: org.research_points.map((rp) => {
              if (!matchedSets[rp.id]) {
                return rp;
              }
              return {
                ...rp,
                tethered: false,
              };
            }),
          };
        });

        return {
          searched: orgs.searched,
          filtered: newFiltered,
        };
      });

      setCellForDisplay((cellForDisplay) => {
        return {
          ...cellForDisplay,
          tethered: false,
        };
      });

      const token = await getAccessTokenSilently();
      await api.put(
        `/funnels/untether`,
        { sets },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (err) {
      console.error('Error fetching datapoints for the funnel:', err);
    }
  };

  const fetchResearchPoints = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await api.get(`/funnels/${id}/research-points`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setResearchPoints(response.data);
    } catch (err) {
      console.error('Error fetching datapoints for the funnel:', err);
    }
  };

  const removeDatapoints = async (removeIds) => {
    try {
      const token = await getAccessTokenSilently();
      await api.delete(`/funnels/${id}/research-points`, {
        data: { research_point_ids: removeIds },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchResearchPoints();
    } catch (error) {
      console.error('Error updating datapoints:', error);
    }
  };

  const addDatapoints = async (addIds) => {
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/funnels/${id}/research-points`,
        {
          research_point_ids: addIds,
          type: researchPointType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      fetchResearchPoints();
    } catch (error) {
      console.error('Error updating datapoints:', error);
    }
  };

  const handleColumnTypeSelect = (type) => {
    setShowAddColumnModal(false);
    if (type === 'research') {
      setResearchPointType('datapoint');
      setShowResearchPointSelectorModal(true);
    } else if (type === 'manual') {
      setShowAdColumnConfigModal(true);
    }
  };

  const handleAddDatapoints = (add) => {
    // Optimistic add
    setResearchPoints((prevResearchPoints) => {
      return [...prevResearchPoints, add];
    });
    setShowResearchPointSelectorModal(false);
    setShowResearchPointChooser(false);

    addDatapoints(add.map((x) => x.id));
  };

  const handleDeleteDatapoints = (remove) => {
    /*
      Optimistic updating
    */

    setResearchPoints((prevResearchPoints) => {
      // Remove the ones where ids are in remove and add the ones to the end where we don't have them
      const updatedResearchPoints = prevResearchPoints.filter(
        (point) => !remove.find((x) => x === point.id),
      );

      return updatedResearchPoints;
    });

    removeDatapoints(remove);
  };

  useEffect(() => {
    if (funnelLoadAttempted && stagesLoadAttempted) {
      setFunnelLoading(false);
    }
  }, [funnelLoadAttempted, stagesLoadAttempted]);

  useEffect(() => {
    setFunnelLoadAttempted(false);
    setStageLoadAttempted(false);

    fetchFunnel();
    fetchOrgs();
    fetchStages();
    fetchFunnelRunStatus();
    fetchResearchPoints();
  }, [id, getAccessTokenSilently]);

  // Define extra details display function
  const getExtraDetails = (stageName, type, metadata) => {
    const stageConfig = functionManifests[stageName];
    if (stageConfig) {
      const typeConfig = stageConfig.find((item) => item.type === type);
      if (
        typeConfig &&
        typeConfig.display &&
        typeConfig.display.how === 'text'
      ) {
        const metadataField = typeConfig.display.show;
        return metadata[metadataField];
      }
      if (
        typeConfig &&
        typeConfig.display &&
        typeConfig.display.how === 'array'
      ) {
        const metadataField = typeConfig.display.show;
        return metadata[metadataField].join(', ');
      }
      if (
        typeConfig &&
        typeConfig.display &&
        typeof typeConfig.display.how === 'function'
      ) {
        const metadataField = typeConfig.display.show;
        return typeConfig.display.how(metadata[metadataField], metadata);
      }
    }
    return null;
  };

  const isEffectivelyEmpty = (stageName) => {
    if (!stages[stageName]) return true;
    if (stages[stageName].length === 0) return true;
    const stageConfig = functionManifests[stageName];
    const typeConfig = stageConfig.find(
      (item) => item.type === stages[stageName][0].type,
    );

    if (stages[stageName].length > 1) {
      return false;
    }
    return typeConfig.hide_from_selection;
  };

  // Define extra details display function
  const getNameForStageType = (stageName, type) => {
    const stageConfig = functionManifests[stageName];
    if (stageConfig) {
      const typeConfig = stageConfig.find((item) => item.type === type);
      if (typeConfig) return typeConfig.name;
      return type;
    }
    return null;
  };

  const getShouldHide = (stageName, type) => {
    const stageConfig = functionManifests[stageName];
    if (stageConfig) {
      const typeConfig = stageConfig.find((item) => item.type === type);
      if (typeConfig) return typeConfig.hide_from_selection;
      return type;
    }
    return true;
  };

  const polite = {
    company_search: 'Source of companies',
    company_filter: 'Company Filters',
  };

  const isImport = () => {
    if (!stages?.company_search) return true;
    const stageConfig = functionManifests.company_search;
    if (stageConfig) {
      const typeConfig = stageConfig.find(
        (item) => item.type === stages.company_search[0].type,
      );
      if (typeConfig) return typeConfig.import;
    }
    return true;
  };

  const isCRMType = () => {
    if (!stages?.company_search) return false;
    const stageConfig = functionManifests.company_search;
    if (stageConfig) {
      const typeConfig = stageConfig.find(
        (item) => item.type === stages.company_search[0].type,
      );
      if (typeConfig) return typeConfig.is_crm_option;
    }
    return false;
  };

  const canRetrieve = () => {
    if (!stages?.company_search) return true;
    const stageConfig = functionManifests.company_search;
    if (stageConfig) {
      const typeConfig = stageConfig.find(
        (item) => item.type === stages.company_search[0].type,
      );
      if (typeConfig) return typeConfig.can_retrieve;
    }
    return true;
  };

  const clickableClass = (type) => {
    if (currentOrgView === 'filtered') {
      return type === 'filtered' ? 'active' : 'inactive';
    } else {
      return type === 'searched' ? 'active' : 'inactive';
    }
  };

  if (funnelLoading) {
    return (
      <div className='page-wrapper'>
        <div className='funnel-view-page page-inner-wrapper funnel'>
          <div className='page-header-area'>
            <div className='title-and-text'>
              <div className='breadcrumbs'>
                <div className='set'>
                  <Link to='/funnel'>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Back to List Builder
                  </Link>
                </div>
              </div>
              <h3>{funnel?.funnel.name || 'Your List'}</h3>
            </div>
          </div>
          <div className='page-split'>
            <div className='lower-loading-area funnel-loading-area'>
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='page-wrapper'>
      <div className='funnel-view-page page-inner-wrapper funnel'>
        <div className={`page-header-area ${fullscreen ? 'retreated' : ''}`}>
          <div className='title-and-text'>
            <div className='breadcrumbs'>
              <div className='set'>
                <Link to='/funnel'>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  Back to List Builder
                </Link>
              </div>
            </div>
            <h3>{funnel?.funnel.name}</h3>
          </div>
          {(!isImport() || canRetrieve()) && (
            <div className='buttons-area'>
              {/* {canReviewFunnels && (
                <Button variant='outline-primary' onClick={() => setShowSearchHistory(true)}>
                  Review
                </Button>
              )} */}
              <ScheduleFunnelButton
                funnelId={id}
                useCRMLanguage={isCRMType()}
              />
              <Button variant='primary' onClick={() => startOrStop()}>
                <span>
                  {isCRMType() ? (
                    <>
                      {funnelRunStatus === 'processing'
                        ? 'Import in progress'
                        : 'Re-check'}
                    </>
                  ) : (
                    <>
                      {funnelRunStatus === 'processing'
                        ? 'Sourcing in progress'
                        : 'Start sourcing'}
                    </>
                  )}
                </span>
                {funnelRunStatus === 'processing' ? (
                  <Spinner size='sm' />
                ) : (
                  <>
                    {isCRMType() ? (
                      <FontAwesomeIcon icon={faRefresh} />
                    ) : (
                      <FontAwesomeIcon icon={faPlay} />
                    )}
                  </>
                )}
              </Button>
            </div>
          )}
          {isImport() &&
            stages?.company_search &&
            stages?.company_search[0].type === 'list' && (
              <div className='buttons-area'>
                <FurtherUploader
                  funnelId={id}
                  runFunnel={runFunnel}
                  disabled={funnelRunStatus === 'processing'}
                />
              </div>
            )}
        </div>
        <div className='page-split'>
          <div className='main-area'>
            <Agent funnelId={id} embedded onClick={() => startOrStop()} />
            <div className={`stages ${highlightStages ? 'highlight' : ''}`}>
              <Button
                className='columns-button'
                onClick={() => setShowAddColumnModal(true)}
              >
                Columns{' '}
                <div className='red-icon'>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </Button>
              {Object.keys(stages || {})
                .filter((stageName) => !isEffectivelyEmpty(stageName))
                .map((stageName) => (
                  <>
                    <div key={stageName} className='stage-holder'>
                      <div className='stage'>
                        {(stages[stageName] || [])
                          .map((entry, index) => ({
                            ...entry,
                            originalIndex: index,
                          }))
                          .filter(
                            (entry) => !getShouldHide(stageName, entry.type),
                          )
                          .map((entry, index) => {
                            const extraDetails = getExtraDetails(
                              stageName,
                              entry.type,
                              entry.metadata,
                            );
                            const originalIndex = entry.originalIndex;

                            return (
                              <div
                                key={index}
                                className={`stage-entry ${stages[stageName][originalIndex]?.active || stageName === 'company_search' ? 'active' : 'disabled'}`}
                              >
                                <div className='set'>
                                  <p className='description'>
                                    {extraDetails
                                      ? getNameForStageType(
                                          stageName,
                                          entry.type,
                                        )
                                      : polite[stageName]}
                                  </p>
                                  <b>
                                    {extraDetails
                                      ? extraDetails.length > 35
                                        ? extraDetails.slice(0, 35) + '...'
                                        : extraDetails
                                      : getNameForStageType(
                                          stageName,
                                          entry.type,
                                        )}
                                  </b>
                                </div>

                                {(stageName !== 'company_search' ||
                                  !isImport() ||
                                  canRetrieve()) && (
                                  <div
                                    className='edit-line'
                                    onClick={() => {
                                      setEditModalStage(
                                        stages[stageName][originalIndex],
                                      );
                                      setShowEditModal(true);
                                    }}
                                  >
                                    <div className='edit-icon-wrapper'>
                                      <EditIcon /> Edit
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                ))}
            </div>
            <div className='stats-header'>
              <div className='stats-left'>
                {selectedOrgIds.length === 0 && (
                  <>
                    {!cellForDisplay && (
                      <>
                        {!funnel?.funnel?.exclusion_list && (
                          <>
                            {isImport() && (
                              <>
                                <div
                                  className={`stats-area ${clickableClass('searched')}`}
                                >
                                  <p className='label'>Companies imported</p>
                                  <p
                                    className='value'
                                    onClick={() =>
                                      setCurrentOrgView('searched')
                                    }
                                  >
                                    {orgs?.searched?.filter((x) => x.active)
                                      .length || '0'}
                                  </p>
                                </div>
                                {!isEffectivelyEmpty('company_filter') && (
                                  <div className='spacer-sorry-matt'></div>
                                )}
                                {!isEffectivelyEmpty('company_filter') && (
                                  <div
                                    className={`stats-area ${clickableClass('filtered')}`}
                                  >
                                    <p className='label'>Matched filter</p>
                                    <p
                                      className='value'
                                      onClick={() =>
                                        setCurrentOrgView('filtered')
                                      }
                                    >
                                      {orgs?.filtered?.length || '0'}
                                    </p>
                                  </div>
                                )}
                              </>
                            )}

                            {!isImport() && (
                              <>
                                {!isEffectivelyEmpty('company_filter') && (
                                  <div
                                    className={`stats-area ${clickableClass('searched')}`}
                                  >
                                    <p className='label'>Companies sourced</p>
                                    <p
                                      className='value'
                                      onClick={() =>
                                        setCurrentOrgView('searched')
                                      }
                                    >
                                      {orgs?.searched?.length || '0'}
                                    </p>
                                  </div>
                                )}
                                {!isEffectivelyEmpty('company_filter') && (
                                  <div className='spacer-sorry-matt'></div>
                                )}
                                <div
                                  className={`stats-area ${clickableClass('filtered')}`}
                                >
                                  <p className='label'>
                                    {isEffectivelyEmpty('company_filter')
                                      ? 'Companies sourced'
                                      : 'Matched filter'}
                                  </p>
                                  <p
                                    className='value'
                                    onClick={() =>
                                      setCurrentOrgView('filtered')
                                    }
                                  >
                                    {orgs?.filtered?.filter((x) => x.active)
                                      .length || '0'}
                                  </p>
                                </div>
                              </>
                            )}
                          </>
                        )}
                        {funnel?.funnel?.exclusion_list && (
                          <div
                            className={`stats-area exclusion ${clickableClass('searched')}`}
                          >
                            <div className='icon-wrapper'>
                              <ExclusionIcon />
                              <p className='label'>Companies excluded</p>
                            </div>
                            <p
                              className='value'
                              onClick={() => setCurrentOrgView('searched')}
                            >
                              {orgs?.searched?.length || '0'}
                            </p>
                          </div>
                        )}
                      </>
                    )}
                    {cellForDisplay && (
                      <div className='cell-for-display'>
                        <label>Sources:</label>
                        <span
                          className='context-cell'
                          dangerouslySetInnerHTML={{
                            __html: marked(cellForDisplay.markdown),
                          }}
                        ></span>
                        {cellForDisplay.tethered && (
                          <div className='action-section'>
                            <button onClick={() => untether([cellForDisplay])}>
                              Locked
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                {selectedOrgIds.length > 0 && (
                  <div className='action-section'>
                    <Button onClick={handleDelete} className='delete-button'>
                      <TrashIcon />
                      <span>Delete</span>
                    </Button>
                  </div>
                )}
              </div>
              <div className='stats-right funnel-button-area'>
                <button
                  className={`full-screen-button ${fullscreen ? 'active' : 'inactive'}`}
                  onClick={() => toggleFullscreen(!fullscreen)}
                >
                  {fullscreen ? <MinimizeIcon /> : <MaximizeIcon />}{' '}
                  {fullscreen ? 'Minimize' : 'Expand'}
                </button>
                <span>
                  <button
                    className='crm-settings-button'
                    onClick={() => setShowCrmSettingsModal(true)}
                  >
                    <SettingsIcon />
                    CRM Settings
                  </button>
                  <FunnelCRMSettingsModal
                    show={showCrmSettingsModal}
                    handleClose={() => setShowCrmSettingsModal(false)}
                    funnelId={id}
                    selfManaged
                  />
                </span>
                {downloadData && (
                  <DownloadCSVButton
                    name={funnel?.funnel.name}
                    downloadData={downloadData}
                  />
                )}
              </div>
            </div>
            <div className='center-area'>
              <OrgList
                orgs={orgs}
                orgsLoading={orgsLoading}
                refreshOrgs={() => {
                  fetchOrgs();
                }}
                funnelId={id}
                view={currentOrgView}
                isImport={isImport()}
                setCellForDisplay={setCellForDisplay}
                researchPointChanges={researchPoints}
                additionalKeyChanges={additionalKeyChanges}
                hasFilter={!isEffectivelyEmpty('company_filter')}
                refreshResearchPoints={() => fetchResearchPoints()}
                addColumnClick={() => setShowAddColumnModal(true)}
                setDownloadData={setDownloadData}
                updateOrgs={setOrgs}
                untether={untether}
                handleDelete={handleDelete}
                setSelectedOrgIds={setSelectedOrgIds}
                handleDeleteDatapoints={handleDeleteDatapoints}
                handleAdditionalKeyDelete={handleAdditionalKeyDelete}
              />
            </div>
          </div>
        </div>
      </div>
      <CongratsModal
        show={showCongratsModal}
        onHide={() => setShowCongratsModal(false)}
        header='Nice! You’ve started your first active agent!'
      >
        <>
          <p>Your agent is getting straight to work.</p>
          <p>If it needs your approval for anything it’ll let you know.</p>
        </>
      </CongratsModal>

      {showEditModal && (
        <StageEditModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          entry={editModalStage}
          refresh={fetchStages}
        />
      )}

      {showAddColumnModal && (
        <AddColumnModal
          show
          handleClose={() => setShowAddColumnModal(false)}
          onColumnTypeSelect={handleColumnTypeSelect}
        />
      )}

      {showResearchPointSelectorModal && (
        <ResearchPointSelectorModal
          loading={false}
          onChoose={handleAddDatapoints}
          handleClose={() => setShowResearchPointSelectorModal(false)}
          initialResearchPoints={researchPoints}
          type={researchPointType}
          orgCount={orgs?.filtered?.length}
          show
        />
      )}

      {showResearchPointChooser && (
        <ResearchPointChooseModal
          onChoose={(choice) => {
            setResearchPointType(choice);
            setShowResearchPointSelectorModal(true);
          }}
          handleClose={() => setShowResearchPointChooser(false)}
          show
        />
      )}

      {showAdColumnConfigModal && (
        <AdColumnConfigModal
          show
          funnelId={id}
          handleAdd={() => setAdditionalKeyChanges(!additionalKeyChanges)}
          handleClose={() => setShowAdColumnConfigModal(false)}
        />
      )}

      <FunnelRunModal
        show={showRunModal}
        handleClose={() => setShowRunModal(false)}
        handleRun={(maxCompanies) => runFunnel(maxCompanies)}
        hasFilter={
          stages.company_filter?.filter((x) => x.type !== 'no_company_filter')
            .length > 0
        }
        useCRMLanguage={isCRMType()}
      />

      <FunnelSearchHistory
        show={showSearchHistory}
        handleClose={() => setShowSearchHistory(false)}
        funnelId={id}
      />
    </div>
  );
}

export default Funnel;
