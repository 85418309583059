import React, { useState, useEffect } from 'react';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import { Button, Accordion } from 'react-bootstrap';
import './funnel-search-history.css';

const FunnelSearchHistory = ({ show, handleClose, funnelId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [searchRuns, setSearchRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRun, setSelectedRun] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);

  useEffect(() => {
    const fetchSearchRuns = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(
          `/debug/funnel-runs?funnel_id=${funnelId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setSearchRuns(response.data);
      } catch (error) {
        console.error('Error fetching search runs:', error);
      } finally {
        setLoading(false);
      }
    };

    if (show) {
      fetchSearchRuns();
    }
  }, [funnelId, getAccessTokenSilently, show]);

  const handleRunClick = async (run) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/debug/search-details/${run.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSelectedRun(response.data);
    } catch (error) {
      console.error('Error fetching search details:', error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return 'var(--success)';
      case 'error':
        return 'var(--danger)';
      case 'running':
        return 'var(--warning)';
      default:
        return 'var(--secondary)';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}, ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
  };

  const renderSearchList = () => (
    <>
      <DreamModalInner.Header>Search History</DreamModalInner.Header>
      <DreamModalInner.Body>
        <div className='search-runs-list'>
          {searchRuns.map((run) => (
            <div
              key={run.id}
              className='search-run-item'
              onClick={() => handleRunClick(run)}
            >
              <div className='search-type'>{run.search_type}</div>
              <div className='search-run-details'>
                <div>Started: {formatDate(run.started_at)}</div>
                {run.completed_at && (
                  <div>Completed: {formatDate(run.completed_at)}</div>
                )}
                {run.error_message && (
                  <div className='error-message'>{run.error_message}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </DreamModalInner.Body>
    </>
  );

  const renderSearchDetails = () => (
    <>
      <DreamModalInner.Header>
        <Button
          variant='link'
          className='back-button'
          onClick={() => setSelectedRun(null)}
        >
          ← Back
        </Button>
        Search Details
      </DreamModalInner.Header>
      <DreamModalInner.Body>
        <div className='search-details'>
          <div className='search-parameters'>
            <pre>{JSON.stringify(selectedRun.search.parameters, null, 2)}</pre>
          </div>
          <div className='search-pages'>
            <Accordion>
              {selectedRun.pages.map((page) => (
                <Accordion.Item
                  key={page.id}
                  eventKey={page.id.toString()}
                  className='page-section'
                >
                  <Accordion.Header>
                    <div className='page-header'>
                      <span>Page {page.page_number}</span>
                      <span className='result-count'>
                        Results: {page.raw_results_count || 0}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className='results-list'>
                      {page.results.map((result) => (
                        <div
                          key={result.id}
                          className='result-item'
                          onClick={() => setSelectedResult(result)}
                        >
                          <div className='result-header'>
                            <span className='entity-name'>
                              {result.entity_name}
                            </span>
                            <span className='state-badge'>
                              {result.current_state.replace(/_/g, ' ')}
                            </span>
                          </div>
                          {result.state_reason && (
                            <div className='state-reason'>
                              {result.state_reason}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </DreamModalInner.Body>
    </>
  );

  const renderResultDetails = () => (
    <>
      <DreamModalInner.Header>
        <Button
          variant='link'
          className='back-button'
          onClick={() => setSelectedResult(null)}
        >
          ← Back
        </Button>
        Result Details
      </DreamModalInner.Header>
      <DreamModalInner.Body>
        <div className='result-details'>
          <pre>{JSON.stringify(selectedResult, null, 2)}</pre>
        </div>
      </DreamModalInner.Body>
    </>
  );

  return (
    <DreamSideModal show={show} handleClose={handleClose} position='right'>
      <DreamModalInner>
        {loading ? (
          <div className='loading-state'>Loading search history...</div>
        ) : selectedResult ? (
          renderResultDetails()
        ) : selectedRun ? (
          renderSearchDetails()
        ) : (
          renderSearchList()
        )}
      </DreamModalInner>
    </DreamSideModal>
  );
};

export default FunnelSearchHistory;
