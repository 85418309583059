import React from 'react';

import { ReactComponent as AccommodationIcon } from '../../icons/accomodation.svg';
import { ReactComponent as BusinessIcon } from '../../icons/business.svg';

const hotelClasses = [
  { label: '2-star', value: 2 },
  { label: '3-star', value: 3 },
  { label: '4-star', value: 4 },
  { label: '5-star', value: 5 },
];

const manifest = [
  {
    name: 'Hotel Group Search',
    icon: <BusinessIcon />,
    type: 'hotel_group_search',
    description: 'Find companies that are groups or collections of hotels',
    search_category: 'Specialist Searches',
    additional_options: {
      location: {
        description: 'Active location',
        long_description:
          'Your agent will find groups that have hotels in this location (standard OTA search)',
        input_type: 'text',
        dev_name: 'location',
      },
      group_size: {
        description: 'Size of groups',
        long_description:
          '(Optional) what size of groups to include. By default all will be returned',
        input_type: 'multiselect',
        options: [
          { label: '1 - 10 hotels', value: 'VERY_SMALL_GROUP' },
          { label: '11 - 50 hotels', value: 'SMALL_GROUP' },
          { label: '50+ hotels', value: 'LARGE_GROUP' },
        ],
      },
    },
  },
  {
    name: 'Hotels in Group Search',
    icon: <AccommodationIcon />,
    type: 'group_hotel_search',
    description: 'Find hotels that are part of groups or collections.',
    search_category: 'Specialist Searches',
    additional_options: {
      location: {
        description: 'Hotel location',
        long_description: 'Use any search term as you would on an OTA',
        input_type: 'text',
        dev_name: 'location',
      },
      hotel_class: {
        description: 'Hotel class',
        long_description: '(Optional) The classes of hotels to return',
        input_type: 'multiselect',
        options: hotelClasses,
        dev_name: 'hotel_class',
      },
      lower_adr_range: {
        description: 'Lower ADR (USD)',
        long_description: 'The minimum end of the ADR range for the hotel',
        input_type: 'number',
        short: true,
      },
      upper_adr_range: {
        description: 'Upper ADR (USD)',
        long_description: 'The maximum end of the ADR range',
        input_type: 'number',
        short: true,
      },
      hotel_type_guidance: {
        description: 'Guidance on types',
        long_description:
          '(Optional) Give your agent more direction on what kinds of hotels to include. Your agent will have information on the hotel class, general style and ameninities',
        input_type: 'textarea',
      },
      include_big_brands: {
        description: 'Include major brands',
        checkbox_inline_description: (
          <span>
            If selected, your agent will return hotels from Hyatt, Hilton, IHG,
            Accor, Wyndham and Choice.
          </span>
        ),
        input_type: 'checkbox',
      },
      minor_groups: {
        description: 'Other groups or collections to include',
        long_description:
          '(Optional) Choose only hotels that are part of these groups or collections.',
        input_type: 'multiselect-no-options',
      },
    },
  },
  {
    name: 'Independent Hotel Search',
    icon: <AccommodationIcon />,
    type: 'independent_hotel_search',
    description: 'Search for independent hotels, vacation rentals and hostels.',
    search_category: 'Specialist Searches',
    additional_options: {
      location: {
        description: 'Hotel location',
        long_description: 'Use any search term as you would on an OTA',
        input_type: 'text',
        dev_name: 'location',
      },
      hotel_class: {
        description: 'Hotel class',
        long_description: '(Optional) The classes of hotels to return',
        input_type: 'multiselect',
        options: hotelClasses,
        dev_name: 'hotel_class',
      },
      lower_adr_range: {
        description: 'Lower ADR (USD)',
        long_description:
          '(Optional) The minimum end of the ADR range for the hotel',
        input_type: 'number',
        short: true,
      },
      upper_adr_range: {
        description: 'Upper ADR (USD)',
        long_description: '(Optional) The maximum end of the ADR range',
        input_type: 'number',
        short: true,
      },
      hotel_type_guidance: {
        description: 'Guidance on types',
        long_description:
          '(Optional) Give your agent more direction on what kinds of hotels to include. Your agent will have information on the hotel class, general style and ameninities',
        input_type: 'textarea',
      },
      allow_very_small_groups: {
        description: 'Allow very small groups',
        checkbox_inline_description: (
          <span>
            If selected, your agent return a hotel even if they're part of a
            very small group (less than 10 properties)
          </span>
        ),
        input_type: 'checkbox',
      },
    },
    credit_cost: 2,
  },
];

export default manifest;
