import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import api from '../../../api';
import DreamInfoExpander from '../../misc/DreamInfoExpander';
import { debounce } from 'lodash';
import DreamSideModalLoader from '../../misc/DreamSideModalLoader';
import { Link } from 'react-router-dom';

/*
 This combines both datapoints & additional data
*/

const longerExplanations = {
  'Job Data': (
    <span className='full-line'>
      <Link
        to='https://docs.google.com/document/d/1huNdVfsEdl2X2H0ccD2jNHipVgvUcN_Uid3oVxRnk78/edit?usp=sharing'
        target='_blank'
      >
        view docs
      </Link>
    </span>
  ),
};

function DatapointsList({ oagentId, refreshDatapoints }) {
  const { getAccessTokenSilently } = useAuth0();
  const [datapoints, setDatapoints] = useState([]);
  const [associatedDatapoints, setAssociatedDatapoints] = useState([]);
  const [additionalDataKeys, setAdditionalDataKeys] = useState([]);
  const [associatedAdditionalData, setAssociatedAdditionalData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDatapoints = async () => {
      try {
        const token = await getAccessTokenSilently();

        // Fetch all research points from the user's company
        const allDatapointsResponse = await api.get(
          `/analyst/compatible-research-points/${oagentId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        const datapoints = allDatapointsResponse.data.filter(
          (dp) => dp.type === 'datapoint',
        );
        setDatapoints(datapoints);

        // Fetch already associated research points for the oagent
        const associatedDatapointsResponse = await api.get(
          `/oagents/${oagentId}/research-points`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        refreshDatapoints();
        setAssociatedDatapoints(
          associatedDatapointsResponse.data.filter(
            (x) => x.type === 'datapoint',
          ),
        );

        // Fetch additional data keys
        const additionalDataKeysResponse = await api.get(
          `/oagents/${oagentId}/additional-data-options`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setAdditionalDataKeys(additionalDataKeysResponse.data.uniqueKeys);

        // Fetch already associated additional data for the oagent
        const associatedAdditionalDataResponse = await api.get(
          `/oagents/${oagentId}/additional-data`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setAssociatedAdditionalData(associatedAdditionalDataResponse.data);
      } catch (error) {
        console.error('Error fetching datapoints:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDatapoints();
  }, [getAccessTokenSilently, oagentId]);

  const isDatapointAssociated = (datapointId) =>
    associatedDatapoints.some((assoc) => assoc.id === datapointId);

  const isAdditionalDataAssociated = (key) =>
    associatedAdditionalData.some((assoc) => assoc.key === key);

  const handleToggleDatapoint = async (datapoint) => {
    const token = await getAccessTokenSilently();

    try {
      if (isDatapointAssociated(datapoint.id)) {
        setAssociatedDatapoints(
          associatedDatapoints.filter((assoc) => assoc.id !== datapoint.id),
        );
        await api.delete(
          `/oagents/${oagentId}/research-point/${datapoint.id}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        refreshDatapoints();
      } else {
        setAssociatedDatapoints([
          ...associatedDatapoints,
          {
            id: datapoint.id,
            name: datapoint.name,
            criteria: 'Include all companies',
          },
        ]);
        await api.post(
          `/oagents/${oagentId}/research-points`,
          {
            research_points: [
              {
                research_point_id: datapoint.id,
                criteria: 'Include all companies',
              },
            ],
          },
          { headers: { Authorization: `Bearer ${token}` } },
        );
        refreshDatapoints();
      }
    } catch (error) {
      console.error(
        `Error ${isDatapointAssociated(datapoint.id) ? 'removing' : 'adding'} datapoint:`,
        error,
      );
    }
  };

  const handleToggleAdditionalData = async (key) => {
    const token = await getAccessTokenSilently();

    try {
      if (isAdditionalDataAssociated(key)) {
        setAssociatedAdditionalData(
          associatedAdditionalData.filter((assoc) => assoc.key !== key),
        );
        await api.delete(`/oagents/${oagentId}/additional-data-options`, {
          data: { key },
          headers: { Authorization: `Bearer ${token}` },
        });
        refreshDatapoints();
      } else {
        setAssociatedAdditionalData([
          ...associatedAdditionalData,
          { key, criteria: 'Include all' },
        ]);
        await api.post(
          `/oagents/${oagentId}/additional-data-options`,
          { key },
          { headers: { Authorization: `Bearer ${token}` } },
        );
        refreshDatapoints();
      }
    } catch (error) {
      console.error(
        `Error ${isAdditionalDataAssociated(key) ? 'removing' : 'adding'} additional data:`,
        error,
      );
    }
  };

  const handleCriteriaChange = (id, criteria, type) => {
    if (type === 'datapoint') {
      setAssociatedDatapoints((prevAssociatedDatapoints) =>
        prevAssociatedDatapoints.map((assoc) =>
          assoc.id === id ? { ...assoc, criteria } : assoc,
        ),
      );
    } else {
      setAssociatedAdditionalData((prevAssociatedData) =>
        prevAssociatedData.map((assoc) =>
          assoc.key === id ? { ...assoc, criteria } : assoc,
        ),
      );
    }

    debounceApiCall(id, criteria, type);
  };

  const debounceApiCall = debounce(async (id, criteria, type) => {
    const token = await getAccessTokenSilently();
    try {
      if (type === 'datapoint') {
        await api.put(
          `/oagents/${oagentId}/research-point/${id}`,
          { criteria },
          { headers: { Authorization: `Bearer ${token}` } },
        );
      } else {
        await api.put(
          `/oagents/${oagentId}/additional-data-options`,
          { key: id, criteria },
          { headers: { Authorization: `Bearer ${token}` } },
        );
      }
      refreshDatapoints();
      // No need to update state here since it's already updated in handleCriteriaChange
    } catch (error) {
      console.error('Error updating criteria:', error);
    }
  }, 500); // Debounce criteria change by 500ms

  if (loading) {
    return <DreamSideModalLoader>Loading</DreamSideModalLoader>;
  }

  return (
    <div className='datapoints-list-wrapper'>
      <p>
        You can use your research and manually added fields to target specific
        companies for your campaign.
      </p>
      {(datapoints?.length > 0 || additionalDataKeys?.length > 0) && (
        <div className='datapoints-selection'>
          <label>Select a datapoint to define targeting</label>
          <div className='datapoint-pills'>
            {additionalDataKeys.map((key) => (
              <div
                className={`pill ${isAdditionalDataAssociated(key) ? 'active' : ''}`}
                key={key}
                onClick={() => handleToggleAdditionalData(key)}
              >
                {key}
              </div>
            ))}
            {datapoints?.length > 0 &&
              datapoints.map((datapoint) => (
                <div
                  className={`pill ${isDatapointAssociated(datapoint.id) ? 'active' : ''}`}
                  key={datapoint.id}
                  onClick={() => handleToggleDatapoint(datapoint)}
                >
                  {datapoint.name}
                </div>
              ))}
          </div>
        </div>
      )}
      {datapoints &&
        datapoints.length === 0 &&
        additionalDataKeys.length === 0 && (
          <div>
            <div className='empty-state'>
              <p>Add research to your target lists to filter further</p>
            </div>
          </div>
        )}
      <div className='datapoints-criteria'>
        {associatedAdditionalData.map((assoc) => (
          <div key={assoc.key} className='criteria-wrapper'>
            <label>
              Define targeting - {assoc.key}{' '}
              {longerExplanations[assoc.key] || ''}
            </label>
            <InputGroup>
              <Form.Control
                type='text'
                size='lg'
                value={assoc.criteria}
                onChange={(e) =>
                  handleCriteriaChange(
                    assoc.key,
                    e.target.value,
                    'additionalData',
                  )
                }
              />
            </InputGroup>
          </div>
        ))}
        {associatedDatapoints.map((assoc) => (
          <div key={assoc.id} className='criteria-wrapper'>
            <label>Define targeting - {assoc.name}</label>
            <InputGroup>
              <Form.Control
                type='text'
                size='lg'
                value={assoc.criteria}
                onChange={(e) =>
                  handleCriteriaChange(assoc.id, e.target.value, 'datapoint')
                }
              />
            </InputGroup>
          </div>
        ))}
      </div>
      {(datapoints?.length > 0 || additionalDataKeys?.length > 0) && (
        <DreamInfoExpander
          heading='How to use datapoints and additional data'
          alwaysExpanded={true}
        >
          <p>
            Datapoints and additional data let you filter which companies from
            your lists are included in your campaigns. You can set any
            conditions against your datapoints and additional data, for example;
            “Include all companies with a headquarters in the US” or “Include
            all companies without a headquarters in the US”.
          </p>
        </DreamInfoExpander>
      )}
    </div>
  );
}

export default DatapointsList;
