import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import WhatsappIcon from '../../icons/whatsapp.png';
import GoogleIcon from '../../icons/google.png';
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { QRCodeSVG } from 'qrcode.react';
import api from '../../api';
import { toast } from 'react-toastify';
import WSStatusUpdateReceiver from '../notifications/WSStatusUpdateReceiver';
import './account-linking-modal.css';

const AccountLinkingModal = ({ show, onHide, refreshLinks }) => {
  const [selectedType, setSelectedType] = useState('');
  const [selectedScope, setSelectedScope] = useState('');
  const { user, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [whatsappData, setWhatsappData] = useState(null);
  const [error, setError] = useState(null);

  const handleWhatsAppUpdate = (data) => {
    if (data.status === 'connected') {
      refreshLinks();
      onHide();
    }
  };

  useEffect(() => {
    if (!show || selectedType !== 'whatsapp') return;

    const handleTypeSelection = async (type) => {
      setSelectedType(type);
      setSelectedScope(''); // Clear scope selection if type changes

      if (type === 'whatsapp') {
        try {
          setLoading(true);
          setError(null);
          const token = await getAccessTokenSilently();
          const response = await api.post(
            '/whatsapp/registration-code',
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          setWhatsappData(response.data);
        } catch (error) {
          console.error('Error getting WhatsApp registration code:', error);
          setError(
            'Failed to generate WhatsApp registration code. Please try again.',
          );
        } finally {
          setLoading(false);
        }
      }
    };

    handleTypeSelection(selectedType);
  }, [show, selectedType, user, getAccessTokenSilently, onHide]);

  const types = [
    {
      type: 'google',
      icon: <img src={GoogleIcon} alt='Google' />,
      politeName: 'Google Account',
    },
    {
      type: 'whatsapp',
      icon: <img src={WhatsappIcon} alt='WhatsApp' />,
      politeName: 'WhatsApp Account',
    },
  ];

  const scopes = [
    {
      value: 'Email & Calendar',
      label: 'Calendar & email',
      icon: (
        <>
          <CalendarIcon />
          <PlusIcon />
          <EmailIcon />
        </>
      ),
    },
    { value: 'Calendar', label: 'Calendar only', icon: <CalendarIcon /> },
  ];

  const handleScopeSelection = (scope) => {
    setSelectedScope(scope);
  };

  const handleLinkAccount = async () => {
    try {
      const scope =
        selectedScope === 'Email & Calendar' ? 'email calendar' : 'calendar';

      const url = `/${selectedType}-link/initiate-${selectedType}-oauth?user_id=${user.sub}&scope=${scope}`;

      window.location.href = process.env.REACT_APP_API_URL + url;
    } catch (error) {
      console.error('Error initiating OAuth:', error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <WSStatusUpdateReceiver
        notificationType='whatsapp_connection_update'
        onNotificationReceived={handleWhatsAppUpdate}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new linked account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='account-linking-modal-wrapper'>
            <p>Select your linked account type and connection options.</p>
            <div className='account-type-select'>
              {types.map(({ type, icon, politeName }) => (
                <div
                  key={type}
                  className={`account-type ${selectedType === type ? 'selected' : ''}`}
                  onClick={() => setSelectedType(type)}
                >
                  {icon}
                  <span>{politeName}</span>
                </div>
              ))}
            </div>

            {selectedType === 'google' && (
              <div className='scope-options-outer-wrapper'>
                <div className='header'>
                  <h6>Linking options</h6>
                </div>
                <div className='scope-options'>
                  {scopes.map(({ value, label, icon }) => (
                    <div
                      key={value}
                      className={`scope-option ${selectedScope === value ? 'selected' : ''}`}
                      onClick={() => handleScopeSelection(value)}
                    >
                      <div className='icon-area'>{icon}</div>
                      <h6>{label}</h6>
                    </div>
                  ))}
                </div>
                {selectedScope && selectedScope === 'Calendar' && (
                  <p>
                    <b>Warning:</b> Without email you won't be able to send from
                    this account.
                  </p>
                )}
              </div>
            )}

            {selectedType === 'whatsapp' && (
              <div className='scope-options-outer-wrapper'>
                {loading ? (
                  <div className='whatsapp-qr-wrapper'>
                    <div className='loading-state'>
                      <Spinner animation='border' />
                      <p className='loading-message'>Loading QR Code</p>
                    </div>
                  </div>
                ) : error ? (
                  <div className='whatsapp-qr-wrapper'>
                    <div className='error-state'>{error}</div>
                  </div>
                ) : whatsappData ? (
                  <div className='whatsapp-qr-wrapper'>
                    <div className='qr-code-container'>
                      <QRCodeSVG value={whatsappData.whatsappUrl} size={100} />
                      <p>
                        Follow the QR Code to open WhatsApp and send the
                        registration code.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-primary' onClick={onHide}>
            Cancel
          </Button>
          {selectedType === 'google' && (
            <Button
              variant='primary'
              disabled={!selectedType || !selectedScope}
              onClick={handleLinkAccount}
            >
              Link account
            </Button>
          )}
        </Modal.Footer>
      </WSStatusUpdateReceiver>
    </Modal>
  );
};

export default AccountLinkingModal;
