import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import cc from 'currency-codes';
import api from '../../api';
import FakePic from '../feed/items/FakePic';
import EngagementActivities from './EngagementActivities';

const SuccessfulEngagements = ({ selectedInterval }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [engagements, setEngagements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showActivities, setShowActivities] = useState(false);
  const [selectedEngagement, setSelectedEngagement] = useState(null);

  useEffect(() => {
    const fetchEngagements = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await api.get('/dashboard/successful-engagements', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Find the engagements for the current time period
        const currentPeriod = response.data.periods.find(
          (p) =>
            p.period ===
            (selectedInterval === 30
              ? '30'
              : selectedInterval === 7
                ? '7'
                : 'all'),
        );

        // Filter for offline engagements only
        const offlineEngagements =
          currentPeriod?.engagements?.filter(
            (engagement) => engagement.offline,
          ) || [];

        setEngagements(offlineEngagements);
      } catch (error) {
        console.error('Error fetching successful engagements:', error);
        setError('Failed to load successful engagements');
      } finally {
        setLoading(false);
      }
    };

    fetchEngagements();
  }, [getAccessTokenSilently, selectedInterval]);

  const formatValue = (value, currency) => {
    if (!value || !currency) return null;
    const symbol = cc.code(currency)?.symbol || currency;

    if (value >= 1000000) {
      return `${symbol}${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `${symbol}${(value / 1000).toFixed(1)}K`;
    }
    return `${symbol}${Math.round(value)}`;
  };

  if (loading) {
    return <></>;
  }

  if (error) {
    return (
      <div className='successful-engagements error'>
        <h2>Successful Engagement</h2>
        <div className='error-message'>{error}</div>
      </div>
    );
  }

  if (!engagements.length) {
    return <></>;
  }

  return (
    <div className='successful-engagements'>
      <h3>Successful Engagement</h3>
      <div className='engagements-list'>
        {engagements.map((engagement) => (
          <div key={engagement.id} className='engagement-item'>
            <div className='left-side'>
              <div className='engagement-logo'>
                <FakePic url={engagement.logo_url} type='org' />
              </div>
              <div className='engagement-details'>
                <div className='engagement-name'>{engagement.name}</div>
              </div>
            </div>
            <div
              className='engagement-view'
              onClick={() => {
                setSelectedEngagement(engagement);
                setShowActivities(true);
              }}
              style={{ cursor: 'pointer' }}
            >
              View
            </div>
          </div>
        ))}
      </div>
      {selectedEngagement && (
        <EngagementActivities
          show={showActivities}
          onHide={() => {
            setShowActivities(false);
            setSelectedEngagement(null);
          }}
          orgId={selectedEngagement.id}
          orgName={selectedEngagement.name}
        />
      )}
    </div>
  );
};

export default SuccessfulEngagements;
