import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Spinner } from 'react-bootstrap';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
// import { ReactComponent as ListBuilderIcon } from '../../../icons/list-builder.svg';
// import { ReactComponent as ResearchPointIcon } from '../../../icons/research-point.svg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api';
import EditCompaniesModal from './EditCompaniesModal';
import CampaignTriggersModal from './CampaignTriggersModal';

import '../campaign-new.css';

function CompaniesSettings({ oagentId, setCanSearch }) {
  const { getAccessTokenSilently } = useAuth0();
  const [funnels, setFunnels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showEditCompaniesModal, setShowEditCompaniesModal] = useState(false);
  const [showCampaignTriggersModal, setShowCampaignTriggersModal] =
    useState(false);
  // const [campaignTriggers, setCampaignTriggers] = useState([]);
  const [datapoints, setDatapoints] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  // const [loadingCampaignTriggers, setLoadingCampaignTriggers] = useState(true);

  const fetchFunnels = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/funnels`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFunnels(response.data);
      setCanSearch(response.data.length > 0);
    } catch (error) {
      console.error('Error fetching funnels:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchResearchPoints = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/research-points`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const triggers = response.data.filter(
        (point) => point.type === 'campaign_trigger',
      );
      const datapoints = response.data.filter(
        (point) => point.type === 'datapoint',
      );

      // setCampaignTriggers(triggers);
      setDatapoints(datapoints);
    } catch (error) {
      console.error('Error fetching campaign triggers:', error);
    } finally {
      // setLoadingCampaignTriggers(false);
    }
  };

  const fetchAdditionalData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/additional-data`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdditionalData(response.data);
    } catch (error) {
      console.error('Error fetching additional data:', error);
    }
  };

  const refreshCampaignTriggers = async () => {
    // setLoadingCampaignTriggers(true);
    await fetchResearchPoints();
  };

  useEffect(() => {
    fetchFunnels();
    fetchResearchPoints();
    fetchAdditionalData();
  }, [getAccessTokenSilently, oagentId]);

  // const totalCompaniesCount = funnels.reduce(
  //   (sum, funnel) => sum + parseInt(funnel.company_count, 10),
  //   0,
  // );

  return (
    <>
      <div className='ss-outer'>
        <div className='ss-main-options'>
          <div className='ss-header'>
            <h6>Sources</h6>
            <p>
              Add sources to bring companies and prospects into your campaigns.
              Use datapoints to control which ones make the cut.
            </p>
          </div>

          <div className='companies-area'>
            {!loading && (
              <>
                {funnels.length == 0 ? (
                  <Button
                    className='big-add-button'
                    onClick={() => setShowEditCompaniesModal(true)}
                  >
                    Add sources{' '}
                    <div className='yellow-icon'>
                      <PlusIcon />
                    </div>
                  </Button>
                ) : (
                  <div className='list-builders-wrapper'>
                    <div className='lists-overview'>
                      {funnels.map((funnel) => (
                        <div className='funnel' key={funnel.id}>
                          <p>{funnel.name}</p>
                          <p>({funnel.company_count})</p>
                        </div>
                      ))}
                    </div>
                    {(datapoints.length > 0 || additionalData.length > 0) && (
                      <div className='filter-footer'>
                        <div className='header'>
                          <span>Filtering companies by these datapoints</span>
                        </div>
                        <div className='dp-list'>
                          {' '}
                          {additionalData.map((data) => (
                            <div className='dp' key={data.key}>
                              <p className='label'>{data.key}</p>
                            </div>
                          ))}
                          {datapoints.map((datapoint) => (
                            <div className='dp' key={datapoint.id}>
                              <p className='label'>{datapoint.name}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className='button-area'>
                      <Button
                        variant='outline-primary'
                        onClick={() => {
                          setShowEditCompaniesModal(true);
                        }}
                      >
                        Add or edit sources <PlusIcon />
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
            {loading && (
              <div className='list-builders-wrapper'>
                <div className='loading-wrapper'>
                  <Spinner />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className='campaign-triggers-area'>
          <div className='step-form-header'>
            <h4>Campaign Triggers</h4>
            <p>
              Setup criteria for which companies from your lists should be
              messaged and when.
            </p>
          </div>
          {loadingCampaignTriggers ? (
            <></>
          ) : campaignTriggers.length === 0 ? (
            <div className='empty-state'>
              <div className='red-icon'>
                <ResearchPointIcon />
              </div>
              {funnels.length > 0 && (
                <>
                  <p>
                    Add Campaign Triggers to make sure your message lands at the
                    right time
                  </p>
                  <Button
                    variant='primary'
                    onClick={() => setShowCampaignTriggersModal(true)}
                  >
                    Add Campaign Trigger{' '}
                    <div className='red-icon'>
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                  </Button>
                </>
              )}
              {funnels.length === 0 && (
                <p>You need to add target lists before you can add triggers</p>
              )}
            </div>
          ) : (
            <div className='campaign-triggers-wrapper'>
              <div className='triggers-header'>
                <p className='triggers-rule'>
                  A company won't be contacted unless{' '}
                  {campaignTriggers.length > 1
                    ? 'one of these triggers are'
                    : 'this trigger is'}{' '}
                  matched.
                </p>
                <p className='triggers-check'>
                  We'll check companies on your list every month until they
                  match.
                </p>
              </div>
              <div className='triggers-list'>
                {campaignTriggers.map((trigger) => (
                  <div className='campaign-trigger' key={trigger.id}>
                    <div className='left'>
                      <p className='trigger-name'>{trigger.name}</p>
                      <p className='trigger-definition'>{trigger.definition}</p>
                    </div>
                    <div className='right'>
                      <p>
                        This trigger will use a maximum of{' '}
                        <b>
                          {Math.round(trigger.credits * totalCompaniesCount)}
                        </b>{' '}
                        credits per month for all companies
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <Button
                variant='primary'
                onClick={() => setShowCampaignTriggersModal(true)}
              >
                Manage Campaign Triggers
              </Button>
            </div>
          )}
        </div> */}
      </div>
      <EditCompaniesModal
        show={showEditCompaniesModal}
        handleClose={() => setShowEditCompaniesModal(false)}
        refreshFunnels={fetchFunnels}
        refreshDatapoints={fetchResearchPoints}
        oagentId={oagentId}
      />
      <CampaignTriggersModal
        show={showCampaignTriggersModal}
        handleClose={() => setShowCampaignTriggersModal(false)}
        oagentId={oagentId}
        refreshTriggers={refreshCampaignTriggers}
      />
    </>
  );
}

export default CompaniesSettings;
