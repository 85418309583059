import React, { useState, useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import FakePic from '../feed/items/FakePic';
import { ReactComponent as ErrorIcon } from '../../icons/error-exclamation.svg';
import './snapshots.css';

const Snapshots = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [snapshot, setSnapshot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snapshotLoading, setSnapshotLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snapshotError, setSnapshotError] = useState(null);

  useEffect(() => {
    const fetchCampaigns = async () => {
      setError(null);
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/oagents', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const activeCampaigns = response.data.filter(
          (campaign) => !campaign.archived,
        );
        setCampaigns(activeCampaigns);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        setError("We're having trouble getting your data");
      } finally {
        setLoading(false);
      }
    };

    fetchCampaigns();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchSnapshot = async () => {
      if (!selectedCampaign) {
        setSnapshot(null);
        return;
      }

      setSnapshotLoading(true);
      setSnapshotError(null);
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(`/snapshots/${selectedCampaign}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSnapshot(response.data);
      } catch (error) {
        console.error('Error fetching snapshot:', error);
        setSnapshotError("We're having trouble getting your snapshot data");
      } finally {
        setSnapshotLoading(false);
      }
    };

    fetchSnapshot();
  }, [selectedCampaign, getAccessTokenSilently]);

  const getTotalCompanyCount = () => {
    if (!snapshot) return 0;
    return Object.values(snapshot).reduce((sum, companies) => sum + companies.length, 0);
  };

  const getPercentage = (count) => {
    const total = getTotalCompanyCount();
    if (total === 0) return 0;
    return Math.round((count / total) * 100);
  };

  const handleCompanyClick = (domain) => {
    window.open(`https://${domain}`, '_blank');
  };

  const renderSection = (title, companies = [], displayCount = 5) => {
    if (!companies.length) return null;
    const percentage = getPercentage(companies.length);

    return (
      <div className="snapshot-section">
        <div className="section-header">
          <h4 className="section-label">{title}</h4>
          <span className="percentage-indicator">{percentage}%</span>
        </div>
        <div className="company-logos">
          {companies.slice(0, displayCount).map((company) => (
            <div 
              key={company.id}
              className="company-logo-wrapper"
              title={company.name}
              onClick={() => handleCompanyClick(company.domain)}
              role="link"
              tabIndex={0}
            >
              <FakePic
                url={company.logo_url}
                type="org"
                size={32}
              />
            </div>
          ))}
          {companies.length > displayCount && (
            <span className="remaining-count">+{companies.length - displayCount}</span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='snapshots'>
      <div className='page-header-area'>
        <h3>Campaign Snapshots</h3>
        <div className='title-and-text'>
          <p>See how your campaigns are performing at a glance</p>
        </div>
      </div>

      <div className='campaign-selector'>
        <Form.Label>Select Campaign</Form.Label>
        <Form.Select
          value={selectedCampaign || ''}
          onChange={(e) => setSelectedCampaign(e.target.value)}
        >
          <option value=''>Choose a campaign</option>
          {campaigns.map((campaign) => (
            <option key={campaign.id} value={campaign.id}>
              {campaign.name}
            </option>
          ))}
        </Form.Select>
      </div>

      {loading ? (
        <div className='loading-state'>
          <Spinner animation='border' />
          <p>Loading campaigns...</p>
        </div>
      ) : error ? (
        <div className='error-state'>
          <ErrorIcon />
          <p>{error}</p>
        </div>
      ) : selectedCampaign ? (
        snapshotLoading ? (
          <div className='loading-state'>
            <Spinner animation='border' />
            <p>Loading snapshot...</p>
          </div>
        ) : snapshotError ? (
          <div className='error-state'>
            <ErrorIcon />
            <p>{snapshotError}</p>
          </div>
        ) : (
          <div className="snapshot-content">
            <div className='snapshot-sections'>
              {renderSection('Engaging', snapshot?.engaging, 8)}
              {renderSection('Ready to Engage', snapshot?.ready_to_engage, 8)}
              {renderSection('Nurturing', snapshot?.nurturing, 8)}
              {renderSection('Not Interested', snapshot?.not_interested, 8)}
              {renderSection('Uncertain', snapshot?.uncertain, 8)}
            </div>
          </div>
        )
      ) : null}
    </div>
  );
};

export default Snapshots;
