import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import GmailIcon from '../../icons/gmail.png';
import GoogleCalendarIcon from '../../icons/gcalendar.png';
import OutlookIcon from '../../icons/outlook.png';
import OutlookCalendarIcon from '../../icons/outlook-calendar.png';
import WhatsappIcon from '../../icons/whatsapp.png';
import { ReactComponent as UnlinkIcon } from '../../icons/unlink.svg';
import AccountLinkingModal from './AccountLinkingModal.js';
import api from '../../api';

import './linked-accounts.css';

const LinkedAccounts = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [googleLinkedAccounts, setGoogleLinkedAccounts] = useState([]);
  const [outlookLinkedAccounts, setOutlookLinkedAccounts] = useState([]);
  const [whatsappAccounts, setWhatsappAccounts] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();

      // Fetch Google linked accounts
      const googleResponse = await api.get('/google-link', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGoogleLinkedAccounts(googleResponse.data.linkedAccounts);

      // Fetch Outlook linked accounts
      const outlookResponse = await api.get('/microsoft-link', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOutlookLinkedAccounts(outlookResponse.data.linkedAccounts);

      // Fetch WhatsApp accounts
      const whatsappResponse = await api.get('/whatsapp', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWhatsappAccounts(whatsappResponse.data.linkedAccounts || []);
    } catch (error) {
      console.error('Error fetching linked accounts:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [getAccessTokenSilently]);

  const renderAccountTypeIcon = (type, scope) => {
    if (type === 'whatsapp') {
      return <img src={WhatsappIcon} alt='WhatsApp' />;
    }
    if (type === 'google') {
      if (scope.includes('Email') && scope.includes('Calendar')) {
        return (
          <div className='double-logo'>
            <img src={GmailIcon} alt='Gmail' />
            <img src={GoogleCalendarIcon} alt='Google Calendar' />
          </div>
        );
      }
      if (scope.includes('Calendar')) {
        return <img src={GoogleCalendarIcon} alt='Google Calendar' />;
      }
    } else if (type === 'microsoft') {
      if (scope.includes('Email') && scope.includes('Calendar')) {
        return (
          <div className='double-logo'>
            <img src={OutlookIcon} alt='Outlook' />
            <img src={OutlookCalendarIcon} alt='Outlook Calendar' />
          </div>
        );
      }
      if (scope.includes('Calendar')) {
        return <img src={OutlookCalendarIcon} alt='Outlook Calendar' />;
      }
    }
    return null;
  };

  const renderLinkedAccounts = (accounts, type) => {
    return accounts.map((account) => (
      <tr key={account.id}>
        <td className='account-type-cell'>
          {renderAccountTypeIcon(type, account.scope)}
        </td>
        <td>{account.email}</td>
        <td>{account.scope}</td>
        <td>{account.profiles}</td>
      </tr>
    ));
  };

  const allLinkedAccounts = [
    ...googleLinkedAccounts.map((a) => ({ ...a, type: 'google' })),
    ...outlookLinkedAccounts.map((a) => ({ ...a, type: 'microsoft' })),
    ...whatsappAccounts.map((a) => ({ ...a, type: 'whatsapp' })),
  ];

  return (
    <div className='linked-accounts-outer-wrapper'>
      <div className='header'>
        <h5>Linked accounts</h5>
        <p>
          Use this page to link email and calendar accounts that can be used in
          campaigns. Currently we support Google, Microsoft and WhatsApp.
        </p>
      </div>

      {allLinkedAccounts.length === 0 ? (
        <div className='empty-state'>
          <FontAwesomeIcon icon={faLink} />
          <p>You don't yet have any linked accounts</p>
          <Button onClick={() => setShowModal(true)}>
            Add new linked account <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      ) : (
        <div className='linked-accounts-wrapper'>
          <div className='header'>
            <h4>Currently linked</h4>
            <div className='button-area'>
              <Button onClick={() => setShowModal(true)}>
                Add new linked account <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </div>
          <div className='table-area'>
            <table>
              <thead>
                <tr>
                  <th>Account type</th>
                  <th>Details</th>
                  <th>Privileges</th>
                  <th>Linked profiles</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {renderLinkedAccounts(googleLinkedAccounts, 'google')}
                {renderLinkedAccounts(outlookLinkedAccounts, 'microsoft')}
                {renderLinkedAccounts(whatsappAccounts, 'whatsapp')}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <AccountLinkingModal
        show={showModal}
        onHide={() => setShowModal(false)}
        refreshLinks={fetchData}
      />
    </div>
  );
};

export default LinkedAccounts;
